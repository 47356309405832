import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import FileUpload from "../../../../components/FileUpload";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddFileImage from "../../../../images/add_file_image.png";

//display when billing is completely empty
const ReceiptSplashScreen = () => {
  const theme = useTheme();
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up("md"));

  const setIsReceiptFormOpen = useBoundStore(
    (state) => state.setIsReceiptFormOpen
  );

  const setReceiptFormAction = useBoundStore(
    (state) => state.setReceiptFormAction
  );

  const resetReceipt = useBoundStore((state) => state.resetReceipt);

  const addFileInput = useBoundStore((state) => state.addFileInput);

  const handleTakePhoto = () => {
    resetReceipt();
    setReceiptFormAction("newTakePhoto");
    setIsReceiptFormOpen(true);
  };

  const handleUploadFiles = (files: File[]) => {
    files.forEach((fileItem) => addFileInput({ file: fileItem }));

    resetReceipt();
    setReceiptFormAction("newUploadFile");
    setIsReceiptFormOpen(true);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="65vh"
      padding={"20px"}
    >
      <Grid
        container
        textAlign="center"
        alignContent="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <img
            src={AddFileImage}
            width={isBreakpointMd ? "40%" : "100%"}
            alt="logo"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">Přidej účtenky</Typography>
        </Grid>
        <Grid item xs={12}>
          Přidej účtenky k nákupům, které chceš aby ti Seznam proplatil
        </Grid>

        {!isBreakpointMd && (
          <Grid item xs={12} md={6} textAlign="center">
            <Button
              // color="primary"
              color="secondary"
              variant="contained"
              fullWidth
              disableElevation
              startIcon={<AddAPhotoIcon />}
              onClick={() => handleTakePhoto()}
            >
              Vyfotit
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={5}>
          <FileUpload handleUploadFiles={handleUploadFiles} multiple={false} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReceiptSplashScreen;
