import { Box, IconButton, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

interface TakePhotoButtonType {
  takePhoto: () => void;
  close: () => void;
  numberOfCameras: number;
  handleSwitchCamera: () => void;
}

const TakePhotoButton = ({
  takePhoto,
  close,
  numberOfCameras,
  handleSwitchCamera,
}: TakePhotoButtonType) => {
  const uiBackgroundColor = "rgba(0, 0, 0, 0.18)";

  const handleTakePhoto = () => {
    takePhoto();
  };

  return (
    <>
      <Box
        sx={{
          zIndex: 10,
          position: "absolute",
          bottom: 0,
          width: "100%",
          padding: "15px",
          background: uiBackgroundColor,
        }}
      >
        <Grid
          container
          width={"100%"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} textAlign={"center"}>
            <IconButton
              onClick={() => handleTakePhoto()}
              size="large"
              sx={{
                border: "4px solid white",
                width: "75px",
                height: "75px",
                color: "white",
              }}
            >
              <CameraAltIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          zIndex: 10,
          position: "absolute",
          top: 0,
          width: "100%",
          padding: "15px",
          background: uiBackgroundColor,
        }}
      >
        <Grid
          container
          width={"100%"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              onClick={() => close()}
              size="large"
              sx={{ color: "#fff" }}
            >
              <ArrowBackIosIcon fontSize="inherit" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              sx={{
                visibility: numberOfCameras <= 1 ? "hidden" : "visible",
                color: "#fff",
              }}
              onClick={() => handleSwitchCamera()}
              size="large"
            >
              <CameraswitchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TakePhotoButton;
