import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BILLING_CREATE_NAV_URL } from "../../../../utils/billing";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_BILLINGS, CREATE_BILLING } from "./gql";
import LoadingButton from "@mui/lab/LoadingButton";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import AddIcon from "@mui/icons-material/Add";
import { BillingType } from "../../../../types/BillingType";
import BillingOverviewFilter from "./BillingOverviewFilter";
import BillingOverviewEntry from "./BillingOverviewEntry";
import CompanySelect from "./CompanySelect";
import ErrorPage from "../../../../components/ErrorPage";

//TODO: write test
const BillingOverview = () => {
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const isAppLoading = useBoundStore((state) => state.isAppLoading);
  const setAppLoading = useBoundStore((state) => state.setAppLoading);
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const setBillingAction = useBoundStore((state) => state.setBillingAction);
  const setBilling = useBoundStore((state) => state.setBilling);
  const userTokens = useBoundStore((state) => state.userTokens);

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const skeletonHeight = isMobileView ? "95px" : "71px";

  const [billingFilter, setBillingFilter] = useState<string>("all");

  // const [
  //   getBillings,
  //   { loading: billingLoading, error: getBillingsError, data: billingData },
  // ] = useLazyQuery(GET_BILLINGS, {
  //   fetchPolicy: "network-only", // Doesn't check cache before making a network request
  // });

  const [
    getBillings,
    { loading: billingLoading, error: getBillingsError, data: billingData },
  ] = useLazyQuery(GET_BILLINGS, {
    fetchPolicy: "network-only", // Used for first execution
  });

  const [
    createBilling,
    {
      // data: createBillingData,
      loading: createBillingLoading,
      // error: createBillingError,
    },
  ] = useMutation(CREATE_BILLING, {
    update(cache, { data: { createBilling } }) {
      let data: any = cache.readQuery({
        query: GET_BILLINGS,
        variables: { accessToken: selectedToken },
      });
      const newBillings = [createBilling].concat(data.billings);
      cache.writeQuery({
        query: GET_BILLINGS,
        variables: { accessToken: selectedToken },
        data: {
          billings: newBillings,
        },
      });
    },
  });

  const handleAddBilling = () => {
    createBilling({
      variables: {
        accessToken: selectedToken,
      },
      onError(error) {
        console.log(error);
        openPopupMessage(error.message, "error");
        window.scrollTo(0, 0);
      },
      onCompleted(data) {
        console.log("Create billing completed");
        console.log(data.createBilling);

        // setGlobalBilling(parsedData);
        setBilling(data.createBilling);

        // saveToStorage(BILLING_STORAGE_KEY, parsedData);
        setBillingAction("new");
        navigate(BILLING_CREATE_NAV_URL + data.createBilling.billingId);
      },
    });
  };

  const handleFilterChange = (filterValue: string) => {
    setBillingFilter(filterValue);
  };

  const filterBilling = (billing: BillingType) => {
    if (billingFilter === "all") return true;
    return billing.state === billingFilter;
  };

  useEffect(() => {
    if (selectedToken) {
      setAppLoading(true);
      getBillings({
        variables: { accessToken: selectedToken },
        onError(error) {
          console.log(error);
          console.log(error.message);
          openPopupMessage(error?.message, "error");
        },
        onCompleted(data) {
          setAppLoading(false);
        },
      });
    }
  }, [getBillings, openPopupMessage, selectedToken, setAppLoading]);

  if (getBillingsError) {
    return <ErrorPage message={getBillingsError.message} />;
  }

  return (
    <Box mt={3}>
      <Container maxWidth="sm">
        <Box mb={10}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography variant="h1">Přehled</Typography>
            </Grid>

            <Grid item xs={6} sm={4}>
              <BillingOverviewFilter
                billingFilter={billingFilter}
                filterChange={handleFilterChange}
              />
            </Grid>
            {userTokens?.length > 1 && (
              <Grid item xs={12}>
                <CompanySelect />
              </Grid>
            )}

            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleAddBilling}
                  disabled={selectedToken === null}
                  loading={createBillingLoading}
                  disableElevation
                  startIcon={<AddIcon />}
                >
                  Nové vyúčtování
                </LoadingButton>
              </Grid>
            </Grid>
            {billingData?.billings?.length === 0 && (
              <Grid item xs={12} textAlign="center">
                <Typography>Začni přidáním vyúčtování</Typography>
              </Grid>
            )}

            {(isAppLoading || billingLoading) && (
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={skeletonHeight}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={skeletonHeight}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={skeletonHeight}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={skeletonHeight}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={skeletonHeight}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} spacing={1}>
              {billingData?.billings
                ?.filter(filterBilling)
                .map((billingItem: BillingType) => {
                  return (
                    <Grid item xs={12} key={billingItem.billingId}>
                      <BillingOverviewEntry billing={billingItem} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BillingOverview;
