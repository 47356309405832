import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import SupportFile from "../SupportFile";
import { SetStateAction, Dispatch } from "react";
import { SupportFileType } from "../../../../types/SupportFileType";
import AddSupportFileImage from "../../../../images/add_support_file_image.png";
import { FileType } from "../../../../types/FileType";
import { useBoundStore } from "../../../../utils/stores/BoundStore";

interface ReceiptSupportFileProps {
  supportFiles: SupportFileType[];
  setSupportFile: Dispatch<SetStateAction<SupportFileType[]>>;
  setSupportFileDelete: Dispatch<SetStateAction<number[]>>;
  uploadedFiles: number[];
  supportFilesDelete: number[];
}

const ReceiptSupportFile = ({
  supportFiles,
  setSupportFile,
  uploadedFiles,
  setSupportFileDelete,
  supportFilesDelete,
}: ReceiptSupportFileProps) => {
  const theme = useTheme();
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up("md"));

  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);

  return (
    <Grid container spacing={2} alignItems="center">
      {supportFiles.length === 0 && uploadedFiles.length === 0 && (
        <Grid item xs={12} textAlign="center">
          <img
            src={AddSupportFileImage}
            width={isBreakpointMd ? "40%" : "100%"}
            alt="logo"
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        textAlign={
          supportFiles.length === 0 && uploadedFiles.length === 0
            ? "center"
            : "left"
        }
      >
        <Typography variant="h2">
          Dodej podklady k prokázáních pracovních účelů účtenek
        </Typography>
      </Grid>

      {isReceiptEditable && (
        <Grid item xs={12}>
          Příklady: Spropitné = výjezd z platebního terminálu (sken/foto),
          Služební cesta (školení, porada) = jeden z uvedených dokumentů:
          pozvánka, prezenční listina, kopie podkladu ze školení, kopie
          e-mailové komunikace, výjezd z elektronického kalendáře
          prostřednictvím, kterého je porada organizována, zápis z porady nebo
          výkaz práce, ze kterého bude zřejmá účast na poradě.
        </Grid>
      )}

      <Grid item xs={12}>
        <SupportFile
          setSupportFile={setSupportFile}
          supportFiles={supportFiles}
          uploadedFiles={uploadedFiles}
          setSupportFileDelete={setSupportFileDelete}
          supportFilesDelete={supportFilesDelete}
          isDisabled={!isReceiptEditable}
        />
      </Grid>
    </Grid>
  );
};

export default ReceiptSupportFile;
