import { BillingSplitType } from "../../../../types/BillingSplitType";
import { BillingType } from "../../../../types/BillingType";
import { ReceiptType } from "../../../../types/ReceiptType";
import { SplitType } from "../../../../types/SplitType";
import { ValidationErrorType } from "../../../../types/ValidationErrorType";
import {
  SPLIT_AMOUNT_TYPE,
  getCalculatedSplitAmount,
} from "../../../../utils/split";
import { validateReceipt } from "../Receipt/ReceiptValidation";
import { validateSplit } from "../SplitForm/SplitValidation";

export const validateBilling = (
  billing: BillingType,
  billingSplitType: BillingSplitType
): { isValid: boolean; errors: ValidationErrorType } => {
  // console.log("validating Billing case type =" + billingSplitType);
  // console.log(billing);

  let isValid = true;

  let errors: ValidationErrorType = {};

  for (const receiptItem of billing.receipts) {
    const receiptValidationResult = validateReceipt(
      receiptItem,
      billingSplitType
    );

    if (!receiptValidationResult.isValid) {
      isValid = false;
      errors.receipt = "Chyba v účtenkách";
    }

    if (billingSplitType === "splitReceipt") {
      for (const splitItem of receiptItem.splits) {
        const splitValidationResult = validateSplit(splitItem);
        if (!splitValidationResult.isValid) {
          isValid = false;
          errors.splits = "Chyba v rozpočtech";
        }
      }
    }
  }

  if (billingSplitType === "splitBilling") {
    if (billing.splits.length === 0) {
      isValid = false;
      errors.splits = "Musí být přidaný alespoň jeden rozpočet";
    } else {
      let sumSplitAmount = 0;
      let sumReceipts = 0;

      let hasStarType = false;

      billing.receipts.forEach((receiptItem: ReceiptType) => {
        sumReceipts += receiptItem.sum;
      });

      billing.splits.forEach((splitItem: SplitType, index) => {
        sumSplitAmount += getCalculatedSplitAmount(
          splitItem.amountType,
          splitItem.amount,
          billing.sum
        );

        if (splitItem.amountType === SPLIT_AMOUNT_TYPE.STAR) hasStarType = true;
      });

      //JS float precision fix
      sumSplitAmount = parseFloat(sumSplitAmount.toFixed(2));

      if (hasStarType) {
        if (sumReceipts < sumSplitAmount) {
          isValid = false;
          errors.splits = "Částky u rozpočtů nejsou vyplněny správně";
        }
      } else {
        if (sumReceipts - sumSplitAmount !== 0) {
          isValid = false;
          errors.splits = "Zbývající částka k vyúčtování musí být rovna 0";
        }
      }
    }

    for (const splitItem of billing.splits) {
      const splitValidationResult = validateSplit(splitItem);
      if (!splitValidationResult.isValid) {
        isValid = false;
        errors.deepSplits = "Chyba v rozpočtech";
      }
    }
  }

  // console.log(errors);

  return { isValid: isValid, errors: errors };
};
