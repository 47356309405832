import { produce } from "immer";
import { StateCreator } from "zustand";
import { BillingType } from "../../types/BillingType";
import { BillingSplitType } from "../../types/BillingSplitType";
import { SplitType } from "../../types/SplitType";
import { EMPTY_SPLIT } from "../split";
import { EMPTY_BILLING } from "../billing";

type BillingAction = "new" | "edit";

export interface BillingStore {
  billing: BillingType;
  isBillingConfigOpen: boolean;
  isBillingReturned: boolean;
  setIsBillingConfigOpen: (bool: boolean) => void;
  setBillingAction: (action: BillingAction) => void;
  setBilling: (billing: BillingType) => void;
  updateBilling: (key: keyof BillingType, data: any) => void;
  billingSplitType: BillingSplitType;
  setBillingSplitType: (billingSplitType: BillingSplitType) => void;
  isUseAi: boolean;
  setUseAi: (bool: boolean) => void;

  addBillingSplit: () => void;
  removeBillingSplit: (splitIndex: number) => void;
  updateBillingSplit: (
    splitIndex: number,
    key: keyof SplitType,
    value: string | number
  ) => void;

  isBillingDisabled: boolean;
  setBillingDisabled: (bool: boolean) => void;
  setBillingReturned: (bool: boolean) => void;

  // splitPageType: String;
  // splits: [];
  // remainingAmountToSplit: number;
  // amountToSplit: number;
  // splitOptions: Options[];
  // setSplitOptions: (options: Options[]) => void;
}

export const createBillingStore: StateCreator<
  BillingStore,
  [],
  [],
  BillingStore
> = (set) => ({
  billing: EMPTY_BILLING,
  isBillingConfigOpen: false,
  billingSplitType: "splitReceipt",
  isUseAi: true,
  isBillingDisabled: false,
  isBillingReturned: false,

  setIsBillingConfigOpen: (bool) => set(() => ({ isBillingConfigOpen: bool })),

  setBillingAction: (action) => {
    if (action === "new") {
      set({ isBillingConfigOpen: true });
    } else if (action === "edit") {
      set({ isBillingConfigOpen: false });
    }
  },

  // isBillingDisabled: false,

  setBilling: (newBilling) => set(() => ({ billing: newBilling })),

  updateBilling: (fieldKey, data) => {
    set(
      produce((state) => {
        state.billing[fieldKey] = data;
      })
    );
  },

  setBillingSplitType: (newBillingSplitType) =>
    set(() => ({ billingSplitType: newBillingSplitType })),

  setUseAi: (bool) => set(() => ({ isUseAi: bool })),

  addBillingSplit: () =>
    set(
      produce((state) => {
        if (!state.billing.splits) {
          state.billing.splits = [EMPTY_SPLIT];
        } else {
          state.billing.splits.push(EMPTY_SPLIT);
        }
      })
    ),

  removeBillingSplit: (splitIndex) =>
    set(
      produce((state) => {
        state.billing.splits.splice(splitIndex, 1);
      })
    ),

  updateBillingSplit: (splitIndex, key, value) =>
    set(
      produce((state) => {
        state.billing.splits[splitIndex][key] = value;
      })
    ),

  setBillingDisabled: (bool) => set(() => ({ isBillingDisabled: bool })),
  setBillingReturned: (bool) => set(() => ({ isBillingReturned: bool })),
  // updateReceiptInBilling: (receiptId, fieldKey, value) => {
  //   set(
  //     produce((state) => {
  //       const indexToUpdate = state.billing.receipts.findIndex(
  //         (receiptItem) => receiptItem.receiptId === parseInt(receiptId)
  //       );

  //       if (indexToUpdate > -1) {
  //         state.billing.receipts[indexToUpdate][fieldKey] = value;
  //       }
  //     })
  //   );
  // },

  // removeReceiptFromBilling: (receiptId) => {
  //   set(
  //     produce((state) => {
  //       const indexToDelete = state.billing.receipts.findIndex(
  //         (receiptItem) => receiptItem.receiptId === parseInt(receiptId)
  //       );

  //       if (indexToDelete > -1) {
  //         state.billing.receipts.splice(indexToDelete, 1);
  //       }
  //     })
  //   );
  // },

  // addReceiptToBilling: (receipt) => {
  //   set(
  //     produce((state) => {
  //       state.billing.receipts.push(receipt);
  //     })
  //   );
  // },

  // setBillingDisabled: (isDisabled) =>
  //   set(() => ({ isBillingDisabled: isDisabled })),

  // isCameraOn: false,
  // isStackGalleryOpen: false,
  // takenPhotoStack: [],

  // setCameraOn: () => set(() => ({ isCameraOn: true })),
  // setCameraOff: () => set(() => ({ isCameraOn: false })),

  // setStackGalleryOpen: () => set(() => ({ isStackGalleryOpen: true })),
  // setStackGalleryClose: () => set(() => ({ isStackGalleryOpen: false })),
  // setStackGalleryClear: () => set(() => ({ takenPhotoStack: [] })),

  // addTakenPhotoToStack: (encodedPhoto, isUseAi) => {
  //   set(
  //     produce((state) => {
  //       state.takenPhotoStack.push({ image: encodedPhoto, isUseAi: isUseAi });
  //     })
  //   );
  // },

  // removeTakenPhotoFromStack: (index) => {
  //   set(
  //     produce((state) => {
  //       state.takenPhotoStack.splice(index, 1);
  //     })
  //   );
  // },

  // isDrawerReceiptOpen: false,
  // drawerReceiptId: null,

  // setDrawerReceiptOpen: () => set(() => ({ isDrawerReceiptOpen: true })),
  // setDrawerReceiptClose: () => {
  //   set(
  //     produce((state) => {
  //       state.isDrawerReceiptOpen = false;
  //       state.drawerReceiptId = null;
  //     })
  //   );
  // },

  // setDrawerReceiptId: (receiptId) => {
  //   set(
  //     produce((state) => {
  //       state.drawerReceiptId = receiptId;
  //     })
  //   );
  // },

  // freshAddedReceipts: [],

  // addFreshReceipt: (receiptId) => {
  //   set(
  //     produce((state) => {
  //       state.freshAddedReceipts.push(receiptId);
  //     })
  //   );
  // },

  // removeFreshAddedReceipt: (receiptId) =>
  //   set(
  //     produce((state) => {
  //       const indexToDelete = state.freshAddedReceipts.findIndex(
  //         (receiptItemId) => receiptItemId === parseInt(receiptId)
  //       );

  //       if (indexToDelete > -1) {
  //         state.freshAddedReceipts.splice(indexToDelete, 1);
  //       }
  //     })
  //   ),
});
