import React from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  CardActions,
  Divider,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { FormValidationSchema } from "./FormValidationSchema";
import { LoadingButton } from "@mui/lab";

const CreateReincarnation = ({ handleSubmit, initialValues }) => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
            validationSchema={FormValidationSchema}
            enableReinitialize
          >
            {({ submitForm, isSubmitting, isValid }) => (
              <Form>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Převtělit se za...</Typography>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          name="userToId"
                          label="ID uživatele"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <LoadingButton
                      disabled={isSubmitting || !isValid}
                      onClick={submitForm}
                      size="small"
                      variant="contained"
                    >
                      Převtělit
                    </LoadingButton>
                  </CardActions>
                </Card>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </>
  );
};

export default CreateReincarnation;
