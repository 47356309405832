import gql from "graphql-tag";

export const GET_CASHBOXFILE = gql`
  query cashboxFile($fileId: Float!) {
    cashboxFile(fileId: $fileId) {
      base64
      filename
      id
    }
  }
`;
