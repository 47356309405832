import { Alert, Container, Grid } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { SupportFileType } from "../../../../types/SupportFileType";
import { ValidationErrorType } from "../../../../types/ValidationErrorType";
import { BillingType } from "../../../../types/BillingType";
import { GET_BILLINGS } from "../../Dashboard/BillingOverview/gql";
import { BillingSendActionType } from "../../../../types/BillingSendActionType";
import { validateBilling } from "./BillingFormValidation";
import { EMPTY_BILLING, STATES } from "../../../../utils/billing";
import ReceiptForm from "../ReceiptForm";
import BillingHeader from "./BillingHeader";
import BillingFormError from "./BillingFormErrors";
import Receipt from "../Receipt";
import BillingSplit from "./BillingSplit";
import BillingSupportFile from "./BillingSupportFile";
import BillingControl from "./BillingControl";
import { DELETE_BILLING, GET_BILLING, UPDATE_BILLING } from "./gql";

interface BillingFormProps {
  billing: BillingType;
}

const BillingForm = ({ billing }: BillingFormProps) => {
  const { billingId } = useParams();
  const navigate = useNavigate();
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const setAppLoading = useBoundStore((state) => state.setAppLoading);

  const billingSplitType = useBoundStore((state) => state.billingSplitType);
  const setBilling = useBoundStore((state) => state.setBilling);

  const [isShowSplitBillingForm, setShowSplitBillingForm] = useState(false);
  const [isShowSupportFile, setShowSupportFile] = useState(false);

  const [supportFileInput, setSupportFileInput] = useState<SupportFileType[]>(
    []
  );

  const [supportFileInputDelete, setSupportFileInputDelete] = useState<
    number[]
  >([]);

  const setIsBillingConfigOpen = useBoundStore(
    (state) => state.setIsBillingConfigOpen
  );

  const [formErrors, setFormErrors] = useState<ValidationErrorType>({});

  const [sendBilling, { loading: sendBillingLoading }] = useMutation(
    UPDATE_BILLING,
    {
      update(cache, { data: { updateBilling } }) {
        let data: any = cache.readQuery({
          query: GET_BILLINGS,
          variables: { accessToken: selectedToken },
        });

        console.log("CASH UPDATE outer");
        console.log(data);

        if (data?.billings) {
          const indexToUpdate = data.billings.findIndex(
            (billingItem: BillingType) =>
              billingItem.billingId === updateBilling.billingId
          );

          const newBillings = JSON.parse(JSON.stringify(data.billings));

          if (indexToUpdate > -1) {
            newBillings[indexToUpdate] = updateBilling;
          }

          // console.log("CASH UPDATE");
          // console.log(updateBilling);

          // FIXME: uncomment if neccessary
          // setBilling(updateBilling);

          cache.writeQuery({
            query: GET_BILLINGS,
            variables: { accessToken: selectedToken },
            data: {
              billings: newBillings,
            },
          });
        }

        if (billingId) {
          cache.writeQuery({
            query: GET_BILLING,
            variables: {
              accessToken: selectedToken,
              billingId: parseInt(billingId),
            },
            data: {
              billing: updateBilling,
            },
          });
        }
      },
    }
  );

  const [deleteBilling, { loading: deleteBillingLoading }] = useMutation(
    DELETE_BILLING,
    {
      update(cache, { data: { deleteBilling } }) {
        let data: any = cache.readQuery({
          query: GET_BILLINGS,
          variables: { accessToken: selectedToken },
        });

        if (data?.billings) {
          const indexToDelete = data.billings.findIndex(
            (billingItem: BillingType) =>
              billingItem.billingId === deleteBilling
          );

          const newBillings = JSON.parse(JSON.stringify(data.billings));

          if (indexToDelete > -1) {
            newBillings.splice(indexToDelete, 1);
          }

          cache.writeQuery({
            query: GET_BILLINGS,
            variables: { accessToken: selectedToken },
            data: {
              billings: newBillings,
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (
      billingSplitType === "splitBilling" &&
      billing?.receipts &&
      billing.receipts.length > 0
    ) {
      setShowSplitBillingForm(true);
    } else {
      setShowSplitBillingForm(false);
    }
  }, [billing, billingSplitType]);

  useEffect(() => {
    if (billing?.receipts && billing.receipts.length > 0) {
      setShowSupportFile(true);
    } else {
      setShowSupportFile(false);
    }
  }, [billing, billingSplitType]);

  const handleSendBilling = (action: BillingSendActionType) => {
    let updateState = billing?.state;
    if (action === "send") {
      const billingValidationResult = validateBilling(
        billing,
        billingSplitType
      );
      if (!billingValidationResult.isValid) {
        setFormErrors(billingValidationResult.errors);
        window.scrollTo(0, 0);
        return;
      }
      updateState = STATES.SEND;
    }

    console.log("DATA MODEL BILLING");
    console.log(billing);

    let billingUpdateInput = {
      sum: billing?.sum,
      isSplitBilling: billing?.isSplitBilling,
      state: updateState,
      currency: billing?.currency,
      name: billing?.name,
      billingSupportFiles: billing?.billingSupportFiles,
      splits: billing?.splits,
      receipts: null,
      year: billing?.year,
      billingId: billing?.billingId,
      updatedAt: billing?.updatedAt,
    };

    console.log("SENDING BILLING TO UPDATE");
    console.log(billingUpdateInput);

    sendBilling({
      variables: {
        accessToken: selectedToken,
        data: billingUpdateInput,
        supportFileInput: {
          upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file !== "string")
            .map((fileInputItem) => fileInputItem.file),

          base64Upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file === "string")
            .map((fileInputItem) => fileInputItem.file),
          delete: supportFileInputDelete,
        },
      },
      onError(error) {
        console.log(error.message);
        openPopupMessage(error?.message, "error");
      },
      onCompleted(data) {
        const updatedBilling: BillingType = data.updateBilling;

        console.log("UDPATE BILLING DONE RESPONSE");
        console.log(updatedBilling);
        setBilling(updatedBilling);

        setSupportFileInput([]);
        setIsBillingConfigOpen(false);

        if (action === "save") {
          openPopupMessage("Uloženo", "success");
        } else {
          openPopupMessage("Odesláno", "success");
          navigate("/");
        }
      },
    });
  };

  const handleDeleteBilling = (billingId: number) => {
    setAppLoading(true);
    deleteBilling({
      variables: { accessToken: selectedToken, billingId: billingId },
      onError(error) {
        console.log(error.message);
        setAppLoading(false);
        openPopupMessage(error?.message, "error");
      },
      onCompleted(data) {
        console.log("DELETE BILLING DONE RESPONSE");
        console.log(data);
        openPopupMessage("Smazáno", "success");
        setBilling(EMPTY_BILLING);
        setAppLoading(false);
        navigate("/");
      },
    });
  };

  return (
    <>
      <ReceiptForm />
      <BillingHeader
        sendBilling={() => handleSendBilling("save")}
        deleteBilling={handleDeleteBilling}
        isDeleteLoading={deleteBillingLoading}
        isUpdateLoading={sendBillingLoading}
      />
      <Container maxWidth="md">
        <Grid container spacing={3}>
          {/* TODO: implement when data is ready from server */}
          {false && (
            <Grid item xs={12}>
              <Alert severity="info">
                Vytvořeno <b>Thang Do</b> pro <b>Adam Novák</b>
              </Alert>
            </Grid>
          )}
          {billing.message && billing.message.length > 0 && (
            <Grid item xs={12}>
              <Alert severity="warning">{billing.message}</Alert>
            </Grid>
          )}

          {Object.keys(formErrors).length > 0 &&
            !validateBilling(billing, billingSplitType).isValid && (
              <Grid item xs={12}>
                <BillingFormError />
              </Grid>
            )}

          <Grid item xs={12}>
            <Receipt />
          </Grid>
          {isShowSplitBillingForm && (
            <>
              <Grid item xs={12}>
                <BillingSplit />
              </Grid>
            </>
          )}
          {isShowSupportFile && (
            <Grid item xs={12}>
              <BillingSupportFile
                supportFiles={supportFileInput}
                setSupportFile={setSupportFileInput}
                uploadedFiles={billing.billingSupportFiles}
                setSupportFileDelete={setSupportFileInputDelete}
                supportFilesDelete={supportFileInputDelete}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      {isShowSupportFile && (
        <BillingControl
          sendBilling={handleSendBilling}
          isSendBillingLoading={sendBillingLoading}
        />
      )}
    </>
  );
};

export default BillingForm;
