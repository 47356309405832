import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import PercentIcon from "@mui/icons-material/Percent";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { SPLIT_AMOUNT_TYPE } from "../../../../../utils/split";
import { SplitType } from "../../../../../types/SplitType";

interface SplitTypePickerProps {
  handleUpdate: (key: keyof SplitType, value: string) => void;
  split: SplitType;
  disabled: boolean;
  isStarDisabled: boolean;
}

const SplitTypePicker = ({
  handleUpdate,
  split,
  disabled,
  isStarDisabled,
}: SplitTypePickerProps) => {
  const handleAmountTypeChange = (amountType: string) => {
    handleUpdate("amountType", amountType);
  };

  return (
    <ToggleButtonGroup
      disabled={disabled}
      size="small"
      value={split.amountType || SPLIT_AMOUNT_TYPE.CURRENCY}
      exclusive
      onChange={
        (e, amountType) => handleAmountTypeChange(amountType)
        // setFieldValue(`splits.${splitIndex}.amountType`, amountType)
      }
    >
      <ToggleButton
        value={SPLIT_AMOUNT_TYPE.CURRENCY}
        disabled={split.amountType === SPLIT_AMOUNT_TYPE.CURRENCY}
      >
        <MoneyIcon />
      </ToggleButton>
      <ToggleButton
        value={SPLIT_AMOUNT_TYPE.PERCENTAGE}
        disabled={split.amountType === SPLIT_AMOUNT_TYPE.PERCENTAGE}
      >
        <PercentIcon />
      </ToggleButton>
      <ToggleButton
        value={SPLIT_AMOUNT_TYPE.STAR}
        disabled={isStarDisabled || split.amountType === SPLIT_AMOUNT_TYPE.STAR}
      >
        <AllInclusiveIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SplitTypePicker;
