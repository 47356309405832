import gql from "graphql-tag";

export const CASHBOX_CREATE = gql`
  mutation cashboxCreate($input: CashboxInput!) {
    cashboxCreate(input: $input)
  }
`;

export const GET_BILLING = gql`
  query billing($accessToken: String!, $billingId: Float!) {
    billing(accessToken: $accessToken, billingId: $billingId) {
      name
      state
      sum
      currency
      createdAt
      updatedAt
      isSplitBilling
      isSplitBySupportedFiles
      employeeId
      createdByUserId
      billingId
      message
      year
      reimbursed_amount
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      billingSupportFiles
      receipts {
        updatedAt
        issueDate
        splits {
          splitId
          amount
          amountType
          amountMoney
          costType
          department
          budget
          contract
          updatedAt
        }
        receiptFiles
        receiptSupportFiles
        sum
        scapiId
        state
        note
        recommendedCostType
        isSplitBySupportedFiles
        supplier
        vat
        receiptId
        receiptRef
      }
    }
  }
`;

// export const UPDATE_BILLING = gql`
//   mutation updateBilling($companyId: String!, $data: String!) {
//     updateBilling(companyId: $companyId, data: $data)
//   }
// `;

export const UPDATE_BILLING = gql`
  mutation updateBilling(
    $accessToken: String!
    $data: BillingUpdateInput!
    $supportFileInput: CashboxInput!
  ) {
    updateBilling(
      accessToken: $accessToken
      data: $data
      supportFileInput: $supportFileInput
    ) {
      name
      state
      sum
      currency
      createdAt
      updatedAt
      isSplitBilling
      isSplitBySupportedFiles
      employeeId
      createdByUserId
      billingId
      message
      year
      reimbursed_amount
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      billingSupportFiles
      receipts {
        updatedAt
        issueDate
        splits {
          splitId
          amount
          amountType
          amountMoney
          costType
          department
          budget
          contract
          updatedAt
        }
        receiptFiles
        receiptSupportFiles
        sum
        scapiId
        state
        note
        recommendedCostType
        isSplitBySupportedFiles
        supplier
        vat
        receiptId
        receiptRef
      }
    }
  }
`;

export const DELETE_BILLING = gql`
  mutation deleteBilling($accessToken: String!, $billingId: Float!) {
    deleteBilling(accessToken: $accessToken, billingId: $billingId)
  }
`;

export const GET_COMPANIES = gql`
  query companies($guid: String!) {
    companies(guid: $guid)
  }
`;

export const GET_SUPPORT_FILES = gql`
  query supportFiles($supportFilesId: String!) {
    supportFiles(supportFilesId: $supportFilesId)
  }
`;

export const CREATE_SPLIT = gql`
  mutation createSplit($accessToken: String!, $input: SplitCreateInput!) {
    createSplit(accessToken: $accessToken, input: $input) {
      splitId
      amount
      amountType
      amountMoney
      costType
      department
      budget
      contract
      updatedAt
    }
  }
`;

export const DELETE_SPLIT = gql`
  mutation deleteSplit($companyId: String!, $splitId: String!) {
    deleteSplit(companyId: $companyId, splitId: $splitId)
  }
`;

export const UPDATE_SPLIT = gql`
  mutation updateSplit($companyId: String!, $data: String!) {
    updateSplit(companyId: $companyId, data: $data)
  }
`;

export const GET_SPLITS = gql`
  query splits(
    $companyId: String!
    $splitDocumentType: String!
    $splitDocumentId: String!
  ) {
    splits(
      companyId: $companyId
      splitDocumentType: $splitDocumentType
      splitDocumentId: $splitDocumentId
    )
  }
`;
