import { Chip, Box } from "@mui/material";
import { truncateText } from "../../../../../utils/strings";
import { SplitOptionType } from "../../../../../types/SplitOptionType";
import { ParsedOptionsType } from ".";

interface SplitFilterProps {
  filter: any;
  removeFilter: (key: keyof SplitOptionType) => void;
  getSplitValueLabel: (
    splitOptionKey: keyof ParsedOptionsType,
    value: string
  ) => string;
  isDisabled: boolean;
}

const SplitFilter = ({
  filter,
  removeFilter,
  getSplitValueLabel,
  isDisabled,
}: SplitFilterProps) => {
  return (
    <>
      {Object.keys(filter).some((filterKey) => filter[filterKey] != null)
        ? Object.keys(filter).map((filterKey) => {
            if (filter[filterKey] != null && filterKey !== "costType") {
              return (
                <Box key={filterKey} component="span" ml={"2px"} mr={"2px"}>
                  <Chip
                    label={truncateText(
                      getSplitValueLabel(
                        filterKey as keyof ParsedOptionsType,
                        filter[filterKey]
                      ),
                      15
                    )}
                    variant="outlined"
                    onDelete={() =>
                      removeFilter(filterKey as keyof SplitOptionType)
                    }
                    disabled={isDisabled}
                  />
                </Box>
              );
            }
            return "";
          })
        : " žádné"}
    </>
  );
};

export default SplitFilter;
