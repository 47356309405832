// vypis vsech splitu na Billingu pri hromadnem vyuctovani
// mel by se zde znovu pouzit SplitForm

import { Button, Grid, Typography } from "@mui/material";
import SplitForm from "../SplitForm";
import AddIcon from "@mui/icons-material/Add";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { SplitType } from "../../../../types/SplitType";

const BillingSplit = () => {
  const addBillingSplit = useBoundStore((state) => state.addBillingSplit);
  const billing = useBoundStore((state) => state.billing);
  const removeBillingSplit = useBoundStore((state) => state.removeBillingSplit);
  const updateBillingSplit = useBoundStore((state) => state.updateBillingSplit);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);

  return (
    <>
      <Typography variant="h2">Rozpočet</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SplitForm
            splits={billing?.splits || new Array<SplitType>()}
            removeSplit={removeBillingSplit}
            updateSplit={updateBillingSplit}
            sumAmount={billing.receipts.reduce(
              (prev, curr) => prev + curr.sum,
              0
            )}
            isDisabled={isBillingDisabled}
          />
        </Grid>

        {!isBillingDisabled && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disableElevation
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addBillingSplit}
            >
              Rozpočet
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BillingSplit;
