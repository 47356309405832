import { Alert, Box } from "@mui/material";
import { ReceiptType } from "../../../../types/ReceiptType";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { validateReceipt } from "../Receipt/ReceiptValidation";
import { SplitType } from "../../../../types/SplitType";
import { validateSplit } from "../SplitForm/SplitValidation";
import { validateBilling } from "./BillingFormValidation";

const BillingFormError = () => {
  const billing = useBoundStore((state) => state.billing);
  const billingSplitType = useBoundStore((state) => state.billingSplitType);

  // if (validateBilling(billing, billingSplitType).isValid) return <></>;

  return (
    <Alert severity="error">
      {billing.receipts.map((receiptItem: ReceiptType) => {
        const receiptValidationResult = validateReceipt(
          receiptItem,
          billingSplitType
        );

        return (
          <>
            {Object.keys(receiptValidationResult.errors).length > 0 && (
              <>Účtenka {receiptItem.receiptRef}</>
            )}
            {Object.keys(receiptValidationResult.errors).map(
              (receiptErrorKey: string) => {
                const receiptError =
                  receiptValidationResult.errors[receiptErrorKey];
                return (
                  <Box ml={1}>
                    {receiptError}
                    {billingSplitType === "splitReceipt" && (
                      <SplitFormError splits={receiptItem.splits} />
                    )}
                  </Box>
                );
              }
            )}
          </>
        );
      })}
      {/* FIXME: make this nice universal into split validation */}
      {billingSplitType === "splitBilling" && (
        <>
          {Object.keys(validateBilling(billing, billingSplitType).errors).map(
            (errorKey) => {
              if (errorKey === "deepSplits")
                return <SplitFormError splits={billing.splits} />;

              return validateBilling(billing, billingSplitType).errors[
                errorKey
              ];
            }
          )}
        </>
        // <>
        //   {billing.splits.length === 0 ? (
        //     "Musí být přidaný alespoň jeden rozpočet"
        //   ) : (
        //     <SplitFormError splits={billing.splits} />
        //   )}
        // </>
      )}
    </Alert>
  );
};

interface SplitFormErrorProps {
  splits: SplitType[];
}

const SplitFormError = ({ splits }: SplitFormErrorProps) => {
  return (
    <>
      {splits.map((splitItem: SplitType, index) => {
        const splitItemValidationResult = validateSplit(splitItem);
        if (splitItemValidationResult.isValid) return "";
        return (
          <Box>
            Rozpočet {index + 1}
            {Object.keys(splitItemValidationResult.errors).map(
              (splitErrorKey) => {
                const splitError =
                  splitItemValidationResult.errors[splitErrorKey];
                return <Box ml={1}>{splitError}</Box>;
              }
            )}
          </Box>
        );
      })}
    </>
  );
};

export default BillingFormError;
