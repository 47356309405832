import { SplitErrorType } from "../../../../types/SplitErrorType";
import { SplitType } from "../../../../types/SplitType";
import {
  CS_SPLIT_FIELD_NAMES,
  SPLIT_AMOUNT_TYPE,
} from "../../../../utils/split";

export const validateSplit = (
  split: SplitType
): { isValid: boolean; errors: SplitErrorType } => {
  let errors: SplitErrorType = {};

  let hasStarAmountType = false;

  if (split.amountType === SPLIT_AMOUNT_TYPE.STAR) hasStarAmountType = true;

  for (const splitValueKey in split) {
    if (
      splitValueKey === "updatedAt" ||
      splitValueKey === "amountMoney" ||
      splitValueKey === "splitId"
    )
      continue;

    if (splitValueKey === "amount" && hasStarAmountType) continue;

    const splitValue = split[splitValueKey as keyof SplitType];

    if (!splitValue) {
      errors[splitValueKey as keyof SplitType] =
        CS_SPLIT_FIELD_NAMES[splitValueKey] + " - Musí být vyplněno";
    }
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

///CHECK LIST OF SPLITS

// export const validateSplit = (
//   splits: SplitType[]
// ): { isValid: boolean; errors: SplitErrorType[] } => {
//   let errors: SplitErrorType[] = [];

//   splits.forEach((splitItem: SplitType, index) => {
//     let splitItemErrors: SplitErrorType = {};

//     for (const splitValueKey in splitItem) {
//       const splitValue = splitItem[splitValueKey as keyof SplitType];
//       if (!splitValue) {
//         splitItemErrors[splitValueKey as keyof SplitType] =
//           splitValueKey + " - Musí být vyplněno";

//         // splitItemErrors.index = index;
//       }
//     }

//     errors.push(splitItemErrors);
//   });

//   return { isValid: Object.keys(errors).length === 0, errors };
// };
