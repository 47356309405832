export const truncateText = (text: string, maxLength: number) => {
  if (!text) return "";

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const replaceDotToComma = (number: number) => {
  try {
    const stringNumber = number.toString();
    return stringNumber.replace(".", ",");
  } catch {
    return number;
  }
};

export const MONTHS_CS_NAMES = {
  0: "Leden",
  1: "Únor",
  2: "Březen",
  3: "Duben",
  4: "Květen",
  5: "Červen",
  6: "Červenec",
  7: "Srpen",
  8: "Září",
  9: "Říjen",
  10: "Listopad",
  11: "Prosinec",
};

const BillingCurrencyCS: { [key: string]: string } = {
  CZK: "Kč",
  EUR: "Є",
  USD: "$",
  // undefined: "Kč",
};
export const getCurrency = (value: string): string => {
  if (!value) return "Kč";

  return BillingCurrencyCS[value];
};
