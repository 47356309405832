import { Grid, IconButton, Paper, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SupportFileType } from "../../../../types/SupportFileType";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import DocumentThumbnail from "../../../../components/DocumentThumbnail/DocumentThumbnail";
import { useState } from "react";

interface SupportFileEntryProps {
  file: SupportFileType | number;
  // file: number;
  remove: (id: number) => void;
  reverse?: (id: number) => void;
  isDelete: boolean;
  isDisabled: boolean;
}

const SupportFileEntry = ({
  file,
  remove,
  reverse,
  isDelete,
  isDisabled,
}: SupportFileEntryProps) => {
  const [fileName, setFileName] = useState<string>("");

  function instanceOfBase64FileType(object: any): object is SupportFileType {
    // console.log(object);
    if (
      typeof object === "object" &&
      !Array.isArray(object) &&
      object !== null
    ) {
      return "name" in object;
    } else {
      return false;
    }
  }

  const handleRemove = () => {
    if (instanceOfBase64FileType(file)) {
      remove(file.id);
    } else {
      remove(file);
    }
  };

  const handleReverse = () => {
    if (reverse) {
      if (instanceOfBase64FileType(file)) {
        reverse(file.id);
      } else {
        reverse(file);
      }
    }
  };

  const getFileId = () => {
    if (instanceOfBase64FileType(file)) {
      return file.id;
    } else {
      return file;
    }
  };

  const getFileManual = () => {
    if (instanceOfBase64FileType(file)) {
      // if (typeof file.file === "string") {
      //   return file.file;
      // } else {
      //   return file.file;
      // }
      return file.file;
    } else {
      return null;
    }
  };

  // const getFileName = (): string => {
  //   if (instanceOfBase64FileType(file)) {
  //     return file.filename;
  //   } else {
  //     return file.name;
  //   }
  // };

  // const getFile = (): string => {
  //   if (instanceOfBase64FileType(file)) {
  //     return file.base64;
  //   } else {
  //     if (typeof file.file === "string") {
  //       return file.file;
  //     } else if (
  //       file.file.type.includes("jpeg") ||
  //       file.file.type.includes("png")
  //     ) {
  //       return URL.createObjectURL(file.file);
  //     } else {
  //       return "";
  //     }
  //   }
  // };

  const getFileName = (): string => {
    if (fileName.length !== 0) {
      return fileName;
    } else if (instanceOfBase64FileType(file)) {
      return file.name;

      return "";
    }

    return "";
  };

  return (
    <Paper
      variant="outlined"
      sx={{ padding: "12px", background: isDelete ? "#FFB6B6" : "#ececec" }}
    >
      <Grid
        container
        alignItems="center"
        spacing={1}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={10}
          md={isDisabled ? 12 : 10}
          container
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <DocumentThumbnail
              receipt={null}
              isReuploadDisabled={true}
              fileManual={getFileManual()}
              fileId={getFileId()}
              setFileName={setFileName}
            />
          </Grid>
          <Grid item xs={9} md={10}>
            <Typography noWrap>{getFileName()}</Typography>
          </Grid>
        </Grid>
        {!isDisabled && (
          <Grid item textAlign="right">
            {!isDelete ? (
              <IconButton onClick={() => handleRemove()}>
                <HighlightOffIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  if (handleReverse) {
                    handleReverse();
                  }
                }}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default SupportFileEntry;
