import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";

interface ErrorPageType {
  message: string;
}

const ErrorPage = ({ message }: ErrorPageType) => {
  const handlePageRefresh = () => {
    window.location.reload();
  };
  return (
    <Container maxWidth="md">
      <Grid container mt={2} spacing={1} alignContent="center">
        <Grid item xs={12}>
          <Typography variant="h1" mb={1}>
            Nastala chyba
            <ReportIcon
              color="error"
              sx={{ position: "relative", top: "4px" }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} variant="outlined" sx={{ padding: "8px" }}>
          {message}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Typography mt={1} mb={1}>
          Pokud problém stále přetrvává, kontaktuj{" "}
          <a href="mailto:hellboys@firma.seznam.cz">hellboys@firma.seznam.cz</a>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          disableElevation
          onClick={handlePageRefresh}
        >
          Obnovit stránku
        </Button>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
