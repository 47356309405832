import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CardActionArea,
  CardContent,
  Card,
} from "@mui/material";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <Container maxWidth="sm">
      <Box mb={4} mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1">Admin</Typography>
          </Grid>
          <Grid container item rowSpacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardActionArea
                  component={Link}
                  to={"/admin/reincarnation"}
                  size="small"
                >
                  <CardContent>Reinkarnace</CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminDashboard;
