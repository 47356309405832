import gql from "graphql-tag";

export const GET_RECEIPTS = gql`
  query receipts($accessToken: String!, $billingId: String!) {
    receipts(accessToken: $accessToken, billingId: $billingId) {
      updatedAt
      issueDate
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      receiptFiles
      receiptSupportFiles
      sum
      scapiId
      state
      note
      recommendedCostType
      isSplitBySupportedFiles
      supplier
      vat
      receiptId
      receiptRef
    }
  }
`;

export const DELETE_RECEIPT = gql`
  mutation deleteReceipt($accessToken: String!, $receiptId: Float!) {
    deleteReceipt(accessToken: $accessToken, receiptId: $receiptId)
  }
`;
