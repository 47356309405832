// import { produce } from "immer";
// import { SPLIT_PAGE_TYPE } from "../split";
import { StateCreator } from "zustand";
import { SplitOptionContainer } from "../../types/SplitOptionType";

export interface SplitStore {
  // splitPageType: String;
  // splits: [];
  // remainingAmountToSplit: number;
  // amountToSplit: number;
  splitOptions: SplitOptionContainer;
  setSplitOptions: (options: SplitOptionContainer) => void;
}

export const createSplitStore: StateCreator<SplitStore, [], [], SplitStore> = (
  set
) => ({
  splitOptions: { lookups: [], contracts: [] },
  setSplitOptions: (options) => set(() => ({ splitOptions: options })),

  // splitPageType: "",
  // splits: [],
  // remainingAmountToSplit: 0,
  // amountToSplit: 0,
  // setSplitPageTypeBilling: (pageType) =>
  //   set(() => ({ splitPageType: SPLIT_PAGE_TYPE.BILLING })),
  // setSplitPageTypeReceipt: (pageType) =>
  //   set(() => ({ splitPageType: SPLIT_PAGE_TYPE.RECEIPT })),
  // setSplits: (splits) => set(() => ({ splits: splits })),
  // clearSplits: () => set(() => ({ splits: [] })),
  // addSplit: (newSplit) => {
  //   set(
  //     produce((state) => {
  //       state.splits.push(newSplit);
  //     })
  //   );
  // },
  // updateSplitData: (splitId, key, value) => {
  //   set(
  //     produce((state) => {
  //       const indexToUpdate = state.splits.findIndex(
  //         (splitItem) => splitItem.splitId === splitId
  //       );
  //       if (indexToUpdate > -1) {
  //         state.splits[indexToUpdate][key] = value;
  //       }
  //     })
  //   );
  // },
  // replaceSplit: (newSplit) => {
  //   set(
  //     produce((state) => {
  //       const indexToUpdate = state.splits.findIndex(
  //         (splitItem) => splitItem.splitId === newSplit.splitId
  //       );
  //       if (indexToUpdate > -1) {
  //         state.splits[indexToUpdate] = newSplit;
  //       }
  //     })
  //   );
  // },
  // setRemainingAmountToSplit: (value) =>
  //   set(() => ({ remainingAmountToSplit: value })),
  // setAmountToSplit: (value) => set(() => ({ amountToSplit: value })),
});
