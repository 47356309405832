import { useCallback, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import Homepage from "../Homepage";
import Login from "./index";
import { GET_USER_TOKENS, GET_SPLIT_OPTIONS, USER } from "./gql";
import moment from "moment";
import { useBoundStore } from "../../utils/stores/BoundStore";
import { UserTokenType } from "../../types/UserTokenType";

const User = () => {
  const user = useBoundStore((state) => state.user);
  const setUser = useBoundStore((state) => state.setUser);

  const setSplitOptions = useBoundStore((state) => state.setSplitOptions);

  const setAppLoading = useBoundStore((state) => state.setAppLoading);

  const navigate = useNavigate();

  const setSelectedToken = useBoundStore((state) => state.setSelectedToken);
  const setUserTokens = useBoundStore((state) => state.setUserTokens);
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);

  const { data: userData } = useQuery(USER);

  const [getUserTokenData, { data: tokensData }] =
    useLazyQuery(GET_USER_TOKENS);

  const [getSplitOptions] = useLazyQuery(GET_SPLIT_OPTIONS);

  useEffect(() => {
    if (userData && userData.me && user.username === "") {
      setUser(userData.me);
      setAppLoading(true);
      getUserTokenData({
        onError(error) {
          console.log(error);
          openPopupMessage(error?.message, "error");
        },
        onCompleted() {
          setAppLoading(false);
        },
      });
    }
  });

  const fetchSplitOptions = useCallback(
    (token: string) => {
      getSplitOptions({
        variables: {
          accessToken: token,
          //TODO: use obtained year from userprofile
          year: moment(new Date()).format("YYYY"),
        },
        onError(error) {
          console.log(error);
          openPopupMessage(error.message, "error");
        },
        onCompleted(data) {
          setSplitOptions(data.splitOptions);
        },
      });
    },
    [getSplitOptions, openPopupMessage, setSplitOptions]
  );

  useEffect(() => {
    console.log("TOKENS");
    console.log(tokensData);
    if (tokensData && tokensData.tokens) {
      const tokens: UserTokenType[] = tokensData.tokens;
      setSelectedToken(tokens[0].accessToken);
      setUserTokens(tokens);

      fetchSplitOptions(tokens[0].accessToken);

      if (tokens.length !== 1) {
        navigate("/");
      }
    }
  }, [
    fetchSplitOptions,
    navigate,
    setSelectedToken,
    setUserTokens,
    tokensData,
  ]);

  return (
    <>
      {userData?.me?.authorized ? (
        <Homepage />
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </>
  );
};

export default User;
