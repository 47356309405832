import { LoadingButton } from "@mui/lab";
import { AppBar, Button, Grid, Toolbar } from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";

interface ReceiptFormControlProps {
  activeStep: number;
  isLoading: boolean;
  next: () => void;
  prev: () => void;
  save: () => void;
}

const ReceiptFormControl = ({
  activeStep,
  next,
  prev,
  save,
  isLoading,
}: ReceiptFormControlProps) => {
  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);
  //FIXME: use when have validation of receipt create new
  const isValid = true;

  const handleNext = () => {
    next();
  };

  const handleSave = () => {
    if (activeStep === 2 && isValid) {
      save();
    }
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <AppBar
      position="relative"
      color="primary"
      sx={{ top: "auto", bottom: 0, background: "white" }}
    >
      <Toolbar>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handlePrev}
              disableElevation
              startIcon={<ArrowBackIcon />}
            >
              Zpět
            </Button>
          </Grid>
          <Grid item xs={9} md={4}>
            {activeStep === 1 ? (
              <LoadingButton
                fullWidth
                variant="contained"
                disableElevation
                onClick={handleNext}
                startIcon={<ArrowForwardIcon />}
              >
                Další
              </LoadingButton>
            ) : (
              !isBillingDisabled &&
              isReceiptEditable && (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  disableElevation
                  onClick={handleSave}
                  loading={isLoading}
                  startIcon={<SaveIcon />}
                >
                  Uložit
                </LoadingButton>
              )
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ReceiptFormControl;
