import { Alert, Box } from "@mui/material";
import { ReceiptFormErrorType } from "../../../../types/ReceiptFormErrorType";

interface ReceiptFormErrorProps {
  errors: ReceiptFormErrorType;
}

const ReceiptFormError = ({ errors }: ReceiptFormErrorProps) => {
  return (
    <>
      {Object.keys(errors).length > 0 && (
        <Box mb={2}>
          <Alert severity="error">
            Chyba ve formuláři
            {/* {errors.map((errorItem) => (
            <p>{errorItem.message}</p>
          ))} */}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default ReceiptFormError;
