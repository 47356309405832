import gql from "graphql-tag";

export const CREATE_RECEIPT = gql`
  mutation createReceipt(
    $fileInput: CashboxInput!
    $supportFileInput: CashboxInput!
    $receiptInput: ReceiptCreateInput!
    $billingId: String!
    $accessToken: String!
  ) {
    createReceipt(
      fileInput: $fileInput
      supportFileInput: $supportFileInput
      receiptInput: $receiptInput
      billingId: $billingId
      accessToken: $accessToken
    ) {
      updatedAt
      issueDate
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      receiptFiles
      receiptSupportFiles
      sum
      scapiId
      state
      note
      recommendedCostType
      isSplitBySupportedFiles
      supplier
      vat
      receiptId
      receiptRef
    }
  }
`;

export const UPDATE_RECEIPT = gql`
  mutation updateReceipt(
    $receiptInput: ReceiptUpdateInput!
    $accessToken: String!
    $fileInput: CashboxInput
    $supportFileInput: CashboxInput
  ) {
    updateReceipt(
      receiptInput: $receiptInput
      accessToken: $accessToken
      fileInput: $fileInput
      supportFileInput: $supportFileInput
    ) {
      updatedAt
      issueDate
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      receiptFiles
      receiptSupportFiles
      sum
      scapiId
      state
      note
      recommendedCostType
      isSplitBySupportedFiles
      supplier
      vat
      receiptId
      receiptRef
    }
  }
`;

export const READ_DOCUMENT_AI = gql`
  mutation readDocumentAI($fileInput: CashboxInput!) {
    readDocumentAI(fileInput: $fileInput) {
      id
      sum
      issueDate
      warning
      confidence
    }
  }
`;
