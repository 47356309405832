import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const Loading = () => {
  return (
    <Box textAlign={"center"}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
