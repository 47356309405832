import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";

const HelpExamples = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">Příklady vyúčtování</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Příklady vyúčtování</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="pravidelne">
                Pravidelné měsíční vyúčtování
              </Typography>

              <p>
                Mám nastavené a vyplněné rozpočty k vyúčtování. V průběhu měsíce
                si fotím účtenky přímo do Carmen. Na konci daného měsíce nebo na
                začátku příštího měsíce odesílám vyúčtování. Hodí se například pro
                pravidelné parkování.
              </p>

              <Typography fontSize={"1.5em"} id="teambuilding">
                Vyúčtování za teambuilding
              </Typography>

              <p>
                Na konci teambuildingu vytvořím vyúčtování. Volím možnost
                rozpočtů k účtenkám abych mohl rozdělit náklady více detailně.
                Postupně nafotím účtenky a přiřadím k ním rozpočty. Po kontrole
                rovnou odesílám.
              </p>

              <Typography fontSize={"1.5em"} id="pracovni_cesta">
                Pracovní cesta do zahraničí
              </Typography>

              <p>
                Vzhledem k měnícímu se kurzu měny musím dbát na to aby byly
                účtenky rozděleny do vyúčtování podle dnů. Mám tedy za jednu
                pracovní cestu více vyúčtování podle toho ve kolika dnech jsem
                nakupoval.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(1) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#pravidelne">
                    <ListItemText primary="Pravidelné vyúčtování" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#teambuilding">
                    <ListItemText primary="Teambuilding" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#pracovni_cesta">
                    <ListItemText primary="Pracovní cesta" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpExamples;
