import { useEffect, useRef, useState } from "react";
import { Camera, CameraType } from "react-camera-pro";
import TakePhotoButton from "./TakePhotoButton";
import { Box } from "@mui/material";

interface CameraProps {
  takePhoto: (base64Photo: string) => void;
  close: () => void;
}

const CameraPro = ({ takePhoto, close }: CameraProps) => {
  const camera = useRef<CameraType>(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  useEffect(() => {
    if (numberOfCameras >= 2) {
      if (camera !== null && camera.current !== null) {
        camera.current.switchCamera();
      }
    }
  }, [numberOfCameras]);

  const handleTakePhoto = () => {
    if (camera.current) {
      takePhoto(camera.current.takePhoto() as string);
    }
  };

  const handleSwitchCamera = () => {
    if (camera !== null && camera.current !== null) {
      camera.current.switchCamera();
    }
  };

  return (
    <>
      <TakePhotoButton
        takePhoto={handleTakePhoto}
        close={close}
        numberOfCameras={numberOfCameras}
        handleSwitchCamera={handleSwitchCamera}
      />
      <Box
        sx={{
          zIndex: 2,
          "& div div div": {
            color: "red",
            fontWeight: "bold",
            top: "150px",
            position: "absolute",
          },
        }}
      >
        <Camera
          ref={camera}
          numberOfCamerasCallback={setNumberOfCameras}
          errorMessages={{
            noCameraAccessible:
              "Kamera není povolená, prosím povol si jí v nastavení.",
            permissionDenied:
              "Práva pro použití kamery nejsou povoleny. Prosím povol si jí v nastavení.",
            switchCamera: "Nelze přepnout mezi kamerami.",
            canvas: "Není podporován Canvas.",
          }}
        />
      </Box>
    </>
  );
};

export default CameraPro;
