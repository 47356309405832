import { FormControl, MenuItem, Select } from "@mui/material";
import { CS_STATES } from "../../../../utils/billing";

interface BillingOverviewFilterProps {
  billingFilter: string;
  filterChange: (filterValue: string) => void;
}

const BillingOverviewFilter = ({
  billingFilter,
  filterChange,
}: BillingOverviewFilterProps) => {
  return (
    <>
      <FormControl fullWidth size="small">
        <Select
          labelId="labelBillingSelect"
          id="billingSelect"
          value={billingFilter}
          onChange={(e) => filterChange(e.target.value)}
        >
          <MenuItem value={"all"}>Vše</MenuItem>
          {Object.keys(CS_STATES).map((stateKey) => (
            <MenuItem key={stateKey} value={stateKey}>
              {CS_STATES[stateKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default BillingOverviewFilter;
