import { StateCreator } from "zustand";
import { ReceiptType } from "../../types/ReceiptType";
import { SplitType } from "../../types/SplitType";
import { FileType } from "../../types/FileType";
import { produce } from "immer";
import { FileInputType } from "../../types/FileInputType";
import { EMPTY_SPLIT } from "../split";
import moment from "moment";

type ReceiptFormAction =
  | "newTakePhoto"
  | "newUploadFile"
  | "edit"
  | "editTakePhoto"
  | "editUploadFile";

type ReceiptValueType = string | number | SplitType | FileType;

export interface ReceiptStore {
  isReceiptFormOpen: boolean;
  receiptFormAction: ReceiptFormAction;
  setReceiptFormAction: (action: ReceiptFormAction) => void;
  setIsReceiptFormOpen: (bool: boolean) => void;
  setReceipt: (receipt: ReceiptType) => void;
  resetReceipt: () => void;
  updateReceipt: (key: string, value: ReceiptValueType) => void;
  receipt: ReceiptType;
  addSplit: () => void;
  removeSplit: (splitIndex: number) => void;
  updateSplit: (
    splitIndex: number,
    key: keyof SplitType,
    value: string | number
  ) => void;
  fileInput: FileInputType[];
  setFileInput: (file: FileInputType[]) => void;
  addFileInput: (file: FileInputType) => void;
  isReceiptEditable: boolean;
  setIsReceiptEditable: (bool: boolean) => void;
}

const EMPTY_RECEIPT: ReceiptType = {
  sum: 0,
  note: "",
  issueDate: moment().format("YYYY-MM-DD"),
  splits: [EMPTY_SPLIT],
  receiptFiles: [],
  receiptSupportFiles: [],
  isSplitBySupportedFiles: 0,
  state: "N",
};

export const createReceiptStore: StateCreator<
  ReceiptStore,
  [],
  [],
  ReceiptStore
> = (set) => ({
  isReceiptFormOpen: false,
  receiptFormAction: "edit",
  receipt: EMPTY_RECEIPT,
  fileInput: [],
  isReceiptEditable: false,

  setIsReceiptFormOpen: (bool) => set(() => ({ isReceiptFormOpen: bool })),

  setIsReceiptEditable: (bool) => set(() => ({ isReceiptEditable: bool })),

  setReceiptFormAction: (action) => set(() => ({ receiptFormAction: action })),

  setReceipt: (receipt) => set(() => ({ receipt: receipt })),
  resetReceipt: () =>
    set(() => ({ receipt: EMPTY_RECEIPT, receiptFormAction: "edit" })),

  updateReceipt: (key, value) =>
    set(
      produce((state) => {
        state.receipt[key] = value;
      })
    ),

  addSplit: () =>
    set(
      produce((state) => {
        state.receipt.splits.push(EMPTY_SPLIT);
      })
    ),

  removeSplit: (splitIndex) =>
    set(
      produce((state) => {
        state.receipt.splits.splice(splitIndex, 1);
      })
    ),

  updateSplit: (splitIndex, key, value) =>
    set(
      produce((state) => {
        state.receipt.splits[splitIndex][key] = value;
      })
    ),

  setFileInput: (files) => set(() => ({ fileInput: files })),

  addFileInput: (file) =>
    set(
      produce((state) => {
        state.fileInput.push(file);
      })
    ),
});
