import { gql } from "@apollo/client";

export const GET_BILLINGS = gql`
  query billings($accessToken: String!) {
    billings(accessToken: $accessToken) {
      name
      state
      sum
      currency
      createdAt
      updatedAt
      isSplitBilling
      isSplitBySupportedFiles
      employeeId
      createdByUserId
      billingId
      message
      year
      reimbursed_amount
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      billingSupportFiles
    }
  }
`;

export const CREATE_BILLING = gql`
  mutation createBilling($accessToken: String!) {
    createBilling(accessToken: $accessToken) {
      name
      state
      sum
      currency
      createdAt
      updatedAt
      isSplitBilling
      isSplitBySupportedFiles
      employeeId
      createdByUserId
      billingId
      message
      year
      reimbursed_amount
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      billingSupportFiles
    }
  }
`;

export const DELETE_BILLING = gql`
  mutation deleteBilling($accessToken: String!, $billingId: String!) {
    deleteBilling(accessToken: $accessToken, billingId: $billingId)
  }
`;
