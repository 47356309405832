import { Grid, Paper } from "@mui/material";
import { BillingType } from "../../../../types/BillingType";
import { useNavigate } from "react-router-dom";
import { BILLING_CREATE_NAV_URL } from "../../../../utils/billing";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import BillingOverviewEntryState from "./BillingOverviewEntryState";
import { getCurrency, replaceDotToComma } from "../../../../utils/strings";

interface BillingOverviewEntryProps {
  billing: BillingType;
}
const BillingOverviewEntry = ({ billing }: BillingOverviewEntryProps) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    // setBilling(billing);
    navigate(BILLING_CREATE_NAV_URL + billing.billingId.toString());
  };

  return (
    <Paper
      sx={{ padding: "12px", cursor: "pointer" }}
      variant="outlined"
      onClick={handleEdit}
    >
      <Grid container alignItems="center">
        <Grid container item xs={11}>
          <Grid item xs={8}>
            <div key={billing.billingId}>
              {billing.name}{" "}
              {/* <Button onClick={() => handleEdit(billing.billingId)}>edit</Button> */}
            </div>
          </Grid>
          <Grid item xs={4} textAlign="right">
            {replaceDotToComma(billing.sum)} {getCurrency(billing.currency)}
          </Grid>
          <Grid item xs={6} sx={{ fontSize: "14px", color: "grey" }}>
            {moment(billing.createdAt).format("DD.MM.YYYY")}
          </Grid>
          <Grid item xs={6} sx={{ fontSize: "12px" }} textAlign="right">
            <BillingOverviewEntryState state={billing.state} />
          </Grid>
        </Grid>
        <Grid item xs={1} textAlign="right">
          <NavigateNextIcon />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillingOverviewEntry;
