import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface ConfirmDialogProps extends LoadingButtonProps {
  buttonLabel: string | JSX.Element;
  dialogTitle: string;
  dialogText: string;
  handleConfirm: () => void;
  confirmButtonLabel: string;
  confirmLoading: boolean;
  isIcon: boolean;
}

const ConfirmDialog = ({
  buttonLabel,
  confirmButtonLabel,
  dialogTitle,
  dialogText,
  handleConfirm,
  isIcon,
  confirmLoading,
  ...rest
}: ConfirmDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LoadingButton
        {...rest}
        onClick={handleClickOpen}
        disableElevation
        sx={{ minWidth: "5px", padding: isIcon ? 0 : "6px 8px" }}
        loading={confirmLoading}
      >
        {buttonLabel}
      </LoadingButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Zavřít
          </Button>

          <LoadingButton
            disableElevation
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            autoFocus
            color="error"
            variant="contained"
            loading={confirmLoading}
          >
            {confirmButtonLabel}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
