import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BackButton from "../../../../components/Button/BackButton";
import SettingsIcon from "@mui/icons-material/Settings";
import BillingConfig from "./BillingConfig";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

interface BillingHeaderProps {
  sendBilling: () => void;
  deleteBilling: (id: number) => void;
  isDeleteLoading: boolean;
  isUpdateLoading: boolean;
}

const BillingHeader = ({
  sendBilling,
  deleteBilling,
  isDeleteLoading,
  isUpdateLoading,
}: BillingHeaderProps) => {
  const theme = useTheme();
  const setIsBillingConfigOpen = useBoundStore(
    (state) => state.setIsBillingConfigOpen
  );

  const billing = useBoundStore((state) => state.billing);
  return (
    <>
      <Box
        sx={{
          background: "white",
          boxShadow: "0 4px 6px -6px #222;",
        }}
        p={1}
        mb={4}
      >
        <Container
          maxWidth="md"
          disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <BackButton to={"-1"} />
            </Grid>
            <Grid item>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: "400",
                  fontSize:
                    billing?.name && billing?.name.length < 20
                      ? "1.6em"
                      : "1.2em",
                }}
              >
                {billing?.isSplitBilling === 1 ? (
                  <RequestQuoteIcon
                    sx={{
                      position: "relative",
                      top: "5px",
                      color: theme.palette.primary.light,
                    }}
                  />
                ) : (
                  <ReceiptLongIcon
                    sx={{
                      position: "relative",
                      top: "5px",
                      color: theme.palette.primary.light,
                    }}
                  />
                )}
                {billing?.name}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                onClick={() => setIsBillingConfigOpen(true)}
                data-testid="open-config-button"
              >
                <SettingsIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <BillingConfig
        sendBilling={sendBilling}
        deleteBilling={deleteBilling}
        isDeleteLoading={isDeleteLoading}
        isUpdateLoading={isUpdateLoading}
      />
    </>
  );
};

export default BillingHeader;
