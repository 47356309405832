import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SupportIcon from "@mui/icons-material/Support";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { grey } from "@mui/material/colors";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useNavigate } from "react-router-dom";

const helpInfoLinks = [
  {
    title: "Úvod do aplikace",
    icon: <SubjectOutlinedIcon color="primary" />,
    to: "/help/introduction",
  },
  {
    title: "Správa vyučtování",
    icon: <SettingsOutlinedIcon color="primary" />,
    to: "/help/admin",
  },
  {
    title: "Schvalování vyúčtování",
    icon: <CheckCircleOutlineIcon color="primary" />,
    to: "/help/approve",
  },
  {
    title: "Životní cyklus vyúčtování",
    icon: <LoopOutlinedIcon color="primary" />,
    to: "/help/cycle",
  },
  {
    title: "Příklady",
    icon: <FolderCopyOutlinedIcon color="primary" />,
    to: "/help/examples",
  },
  {
    title: "Často kladené otázky (FAQ)",
    icon: <HelpOutlineOutlinedIcon color="primary" />,
    to: "/help/faq",
  },
];

const Help = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const handleNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <Container maxWidth="md">
      <Grid container mt={2} spacing={5} pb={3}>
        <Grid item>
          <Paper sx={{ padding: "24px" }}>
            <Grid container>
              <Grid
                item
                md={7}
                sx={{ borderRight: "1px solid", borderColor: grey[300] }}
              >
                <Box fontSize={"45px"}>
                  <SupportIcon fontSize="inherit" />
                </Box>
                <Typography fontSize={"2.8em"}>Podpora</Typography>
                <Typography fontSize={"1.4em"} color={grey[600]}>
                  Narazili jste na problém, se kterým si nevíte rady?
                  Kontaktujte nás. Rádi vám pomůžeme.
                </Typography>
              </Grid>
              <Grid item md={5} pl={3} pt={2}>
                <Typography fontWeight={"bold"}>Hellboys</Typography>
                <Typography color="primary">
                  <EmailOutlinedIcon fontSize="inherit" />{" "}
                  hellboys@firma.seznam.cz
                </Typography>
                <Typography fontWeight={"bold"} mt={3}>
                  Účetní oddělení
                </Typography>
                <Typography>Simona Penkalová</Typography>
                <Typography color="primary">
                  <EmailOutlinedIcon fontSize="inherit" />{" "}
                  simona.penkalova@firma.seznam.cz
                </Typography>
                <Typography color="primary">
                  <LocalPhoneOutlinedIcon fontSize="inherit" /> 725 918 097
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Typography fontSize={"2em"}>Témata a časté dotazy</Typography>
          <Typography fontSize={"1.4em"} color={grey[600]}>
            Vyberte si z níže uvedených kategorií a rychle najděte odpovědi na
            vaše otázky.
          </Typography>
        </Grid>
        <Grid item container spacing={3}>
          {helpInfoLinks.map((helpLinkItem) => {
            return (
              <Grid item md={4} xs={12}>
                <Paper
                  sx={{
                    padding: "24px",
                    cursor: "pointer",
                    border: "1px solid rgba(0,0,0,0)",
                    "&:hover": {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  onClick={() => handleNavigate(helpLinkItem.to)}
                >
                  <Box>{helpLinkItem.icon}</Box>
                  <Typography fontSize={"1.2em"}>
                    {helpLinkItem.title}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Help;
