import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface ResponsiveFullScreenModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  //   handleClose: () => void;
}
const ResponsiveFullScreenModal = ({
  isOpen,
  children,
}: //   handleClose,
ResponsiveFullScreenModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        // maxWidth="lg"
        // onClose={handleClose}
        // aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        {children}
        {/* <DialogContent></DialogContent> */}
      </Dialog>
    </>
  );
};

export default ResponsiveFullScreenModal;
