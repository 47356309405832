import { Route, Routes } from "react-router-dom";
import MainAppBar from "../../components/layout/MainAppBar";
import Dashboard from "./Dashboard";
import PopupMessage from "./PopupMessage";
import { Navigate } from "react-router-dom";
import { isAdmin } from "../../utils/user";
import AdminDashboard from "../Admin/AdminDashboard";
import Reincarnation from "../Admin/Reincarnation";
import { useBoundStore } from "../../utils/stores/BoundStore";
import Billing from "./Billing";
import BillingPreview from "./BillingPreview";
import Help from "../Help";
import HelpIntroduction from "../Help/HelpIntroduction";
import HelpBillingAdmin from "../Help/HelpBillingAdmin";
import HelpBillingApprove from "../Help/HelpBillingApprove";
import HelpBillingCycle from "../Help/HelpBillingCycle";
import HelpExamples from "../Help/HelpExamples";
import HelpFaq from "../Help/HelpFaq";

const Homepage = () => {
  const user = useBoundStore((state) => state.user);

  const isAdminRights = isAdmin(user);

  return (
    <>
      <MainAppBar />
      <PopupMessage />
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path="/help" element={<Help />} />
        <Route path="/help/introduction" element={<HelpIntroduction />} />
        <Route path="/help/admin" element={<HelpBillingAdmin />} />
        <Route path="/help/approve" element={<HelpBillingApprove />} />
        <Route path="/help/cycle" element={<HelpBillingCycle />} />
        <Route path="/help/examples" element={<HelpExamples />} />
        <Route path="/help/faq" element={<HelpFaq />} />

        {isAdminRights && (
          <>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/reincarnation" element={<Reincarnation />} />
          </>
        )}

        <Route path="/billing/:billingId" element={<Billing />} />

        <Route
          path="/billing/preview/:companyId/:billingToken"
          element={<BillingPreview />}
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default Homepage;
