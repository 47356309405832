import { SplitType } from "../types/SplitType";
import { parseToNumber } from "./billing";

export const SPLIT_PAGE_TYPE = {
  BILLING: "billing",
  RECEIPT: "receipt",
};

type AmountType = "CURRENCY" | "PERCENTAGE" | "STAR";

export const SPLIT_AMOUNT_TYPE: { [key in AmountType]: string } = {
  CURRENCY: "0",
  PERCENTAGE: "1",
  STAR: "2",
};

export const getCalculatedSplitAmount = (
  amountType: string,
  amount: number,
  sumAmount: number
) => {
  const parsedAmount = parseToNumber(amount);

  switch (amountType) {
    case SPLIT_AMOUNT_TYPE.PERCENTAGE:
      const parsedDocumentSum = parseToNumber(sumAmount);

      if (isNaN(parsedAmount)) {
        return 0;
      }

      if (!isNaN(parsedDocumentSum)) {
        return (parsedDocumentSum / 100) * parsedAmount;
      }

      return 0;
    case SPLIT_AMOUNT_TYPE.STAR:
      return 0;
    default:
      if (isNaN(parsedAmount)) {
        return 0;
      }
      return parsedAmount || 0;
  }
};

export const EMPTY_SPLIT: SplitType = {
  splitId: 0,
  amount: 0,
  amountType: SPLIT_AMOUNT_TYPE.CURRENCY,
  amountMoney: 0,
  costType: "",
  department: "",
  budget: "",
  contract: "",
  updatedAt: "",
};

export const CS_SPLIT_FIELD_NAMES: { [key: string]: string } = {
  amount: "Částka",
  costType: "Typ nákladů",
  department: "Nákladové středisko",
  budget: "Rozpočet",
  contract: "Zakázka",
};

export const TYPE_AMOUNT_LABEL: { [key: string]: string } = {
  0: "Částka",
  1: "Procento",
  2: "Automatický dopočet",
};
