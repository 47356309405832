import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";
import NeznamOverview from "../../../images/help/neznam_overview.png";
import NeznamDetail from "../../../images/help/neznam_detail.png";
import BillingGuest from "../../../images/help/billing_guest.gif";
import BillingApproval from "../../../images/help/neznam_approval.gif";
import BillingCancel from "../../../images/help/billing_cacnel.png";

const HelpBillingApprove = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">Schvalování vyúčtování</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Schvalování vyúčtování</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="neznam">Neznám</Typography>

              <img width="100%" src={NeznamOverview} alt="neznám" />

              <p>
                Proces schvalování vyúčtování se odehrává v modulu nazvaném
                "Centrum požadavků - Fronta požadavků", který najdete na interní
                aplikaci "Neznám". Tento modul je klíčovým nástrojem pro správu
                všech žádostí o schválení v rámci firmy, včetně vyúčtování
                Carmen.
              </p>

              <p>
                Pro zpracování vyúčtování budete primárně pracovat se záložkou
                "Nevyřízené". Tato záložka zobrazuje všechny požadavky, které
                dosud nebyly schváleny ani zamítnuty. Zde máte přehled o všech
                nevyřízených vyúčtováních, které čekají na vaši akci.
              </p>

              <Typography fontSize={"1.5em"} id="centrum_pozadavku">Centrum požadavků</Typography>

              <img width="100%" src={NeznamDetail} alt="detail neznámu" />

              <Typography fontSize={"1.2em"}>Filtry požadavků</Typography>

              <p>
                Aby byl proces schvalování ještě efektivnější, modul obsahuje
                filtry, které umožňují rychlé třídění požadavků.
              </p>

              <p>
                <b>Filtr na typ požadavku:</b> Zde můžete vybrat "Carmen", což
                zobrazí pouze ty požadavky, které se týkají schvalování
                vyúčtování Carmen.
              </p>

              <p>
                <b>Filtr na zaměstnance:</b> Tento filtr vám umožní zúžit seznam
                pouze na požadavky od konkrétního zaměstnance.
              </p>

              <p>
                Po aplikaci filtrů se vám zobrazí všechny relevantní požadavky
                na schválení vyúčtování Carmen. Zde můžete přistoupit k
                detailnímu přezkoumání každého požadavku a následně provést
                schválení nebo zamítnutí daného vyúčtování.
              </p>

              <Typography fontSize={"1.2em"}>Základní informace</Typography>

              <p>
                <b>Vytvořil/a:</b> Uvidíte jméno osoby, které dané vyúčtování
                patří. To vám umožní snadno identifikovat, který zaměstnanec
                podal žádost o schválení.
              </p>

              <p>
                <b>Odesláno:</b> Tato informace vám ukazuje, kdy byl požadavek
                odeslán. Pomáhá vám sledovat, jak dlouho je žádost v systému a
                které požadavky by měly být vyřízeny přednostně.
              </p>

              <p>
                <b>Druh žádosti:</b> Zde je uveden typ požadavku. V našem
                případě se zaměřujete na Carmen, ale může zde být uvedeno i jiné
                typy žádostí v závislosti na nastavení filtru.
              </p>

              <p>
                <b>Detail:</b> Každý požadavek má odkaz na detailní zobrazení.
                Kliknutím na tento odkaz se dostanete k podrobnostem vyúčtování
                v Carmen, kde můžete provést důkladnou kontrolu před schválením
                nebo zamítnutím.
              </p>

              <p>
                <b>Suma:</b> Zde je zobrazena celková částka, která je předmětem
                vyúčtování. Tato suma zahrnuje všechny náklady, které
                zaměstnanec zahrnul do vyúčtování.
              </p>

              <p>
                <b>Název:</b> Toto pole obsahuje název, který zaměstnanec zadal
                pro vyúčtování. Název by měl být výstižný a odpovídat obsahu
                vyúčtování, což vám může pomoci rychle pochopit kontext žádosti.
              </p>

              <Typography fontSize={"1.2em"}>
                Detailní informace v Carmen
              </Typography>

              <img width="100%" src={BillingGuest} alt="náhled vyúčtování" />

              <p>
                Po kliknutí na odkaz "Zobrazit v aplikaci" v základních
                informacích žádosti se dostanete do aplikace "Carmen", kde
                můžete vidět všechny podrobnosti o daném vyúčtování. Tato
                obrazovka poskytuje kompletní přehled o všech účtenkách a
                podkladech, které zaměstnanec předložil k vyúčtování.
              </p>

              <p>
                <b>Účtenky:</b> Zde jsou zobrazeny všechny účtenky, které
                zaměstnanec nahrál. Můžete si prohlédnout jednotlivé účtenky,
                zkontrolovat jejich obsah a zajišťovat, že jsou všechny náklady
                oprávněné a odpovídají účelu vyúčtování.
              </p>

              <p>
                <b>Podklady:</b> Kromě účtenek zde mohou být zobrazeny další
                podklady, které zaměstnanec připojil, například faktury, smlouvy
                nebo jiné dokumenty. Tyto podklady slouží k doložení
                oprávněnosti nákladů. Podklady mohou být i u jednotlivých
                účtenek.
              </p>

              <p>
                <b>Rozpočet:</b> V této části uvidíte, jak byly náklady
                rozděleny mezi různé rozpočty. Můžete zkontrolovat, zda jsou
                náklady správně alokovány a zda odpovídají schváleným finančním
                rámcům.
              </p>

              <p>
                <b>Další informace:</b> Kromě výše uvedených položek můžete také
                najít další relevantní informace, jako jsou poznámky zaměstnance
                u účtenek.
              </p>

              <Typography fontSize={"1.5em"} id="vyrizeni_pozadavku">Vyřízení požadavku</Typography>

              <Typography fontSize={"1.2em"}>Schválení</Typography>

              <img width="100%" src={BillingApproval} alt="náhled vyúčtování" />

              <p>
                Pokud jste se rozhodli požadavek schválit, stačí kliknout na
                zelené tlačítko umístěné u požadavku. Tento krok potvrdí, že
                všechny uvedené náklady jsou oprávněné a vyúčtování je v
                pořádku.
              </p>

              <Typography fontSize={"1.2em"}>Zamítnutí</Typography>

              <img width="100%" src={BillingCancel} alt="náhled vyúčtování" />

              <p>
                V případě, že se rozhodnete požadavek zamítnout, klikněte na
                červené tlačítko. Po jeho stisknutí se zobrazí okno, ve kterém
                budete vyzváni k zadání důvodu zamítnutí. Toto odůvodnění je
                důležité pro zaměstnance, aby pochopil, proč jeho vyúčtování
                nebylo schváleno a mohl případně provést opravy či dodat
                potřebné podklady.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(1) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#neznam">
                    <ListItemText primary="Neznám" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#centrum_pozadavku">
                    <ListItemText primary="Centrum požadavků" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#vyrizeni_pozadavku">
                    <ListItemText primary="Vyřízení požadavku" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpBillingApprove;
