import { BillingType } from "../types/BillingType";

export const EMPTY_BILLING: BillingType = {
  name: "",
  state: "",
  sum: 0,
  currency: "",
  createdAt: "",
  updatedAt: "",
  isSplitBilling: 0,
  isSplitBySupportedFiles: 0,
  billingSupportFiles: [],
  receipts: [],
  splits: [],
  employeeId: -1,
  createdByUserId: -1,
  billingId: -1,
  message: "",
  year: 2000,
  reimbursed_amount: "",
};

export const BLANK_BILLING_CREATE = {
  name: "",
  employeeId: 0,
  createdByUserId: 0,
  currency: "CZK",
  //FIXME: waiting for API to have it implemented
  // year: 0,
};

export const CS_STATES: { [key: string]: string } = {
  N: "Rozpracováno",
  S: "Odesláno",
  A: "Schváleno",
  D: "Smazáno",
  V: "Vráceno",
  R: "Zamítnuto",
  C: "Opraveno",
  P: "Ve zpracování",
  I: "Proplaceno",
  Z: "Zrušeno",
  O: "K proplacení",
};

export const STATES = {
  NEW: "N",
  SEND: "S",
  DELETED: "D",
  APPROVED: "A",
  REJECTED: "R",
  RETURNED: "V",
  CORRECTED: "C",
  INPROGRESS: "P",
  REFUNDED: "I",
  CANCELED: "Z",
  TOPAY: "O",
};

export const STATEMENT_STORAGE_KEY = "statements";

export const BILLING_STORAGE_KEY = "currentBilling";

export const RECEIPT_STORAGE_KEY = "currentReceipt";

export const BASE64_IMAGE_KEY = "currentBase64";

export const findDocumentIndex = (draft: any, documentId: any) => {
  const documentIndex = draft.findIndex(
    (document: any) => document.localId === parseInt(documentId)
  );

  return documentIndex;
};

export const CURRENCIES = [
  { value: "CZK", label: "CZK" },
  { value: "EUR", label: "EUR" },
  { value: "USD", label: "USD" },
];

export const BILLING_CREATE_NAV_URL = "/billing/";
export const RECEIPT_CREATE_NAV_URL = "/receipt/";

export const DISABLED_GLOBAL_KEY = "isAllDisabled";

export const parseToNumber = (value: any): number => {
  // if (value === "") {
  //   value = 0;
  // }
  if (isNaN(value) && (typeof value === "string" || value instanceof String)) {
    const whiteSpaceRemoved = value.replace(/ /g, "");
    const commaReplaced = whiteSpaceRemoved.replace(/,/, ".");
    if (Number.isNaN(commaReplaced)) {
      return 0;
    } else {
      return parseFloat(commaReplaced);
    }
  } else {
    return value;
  }
};

export const getStateColor = (billing: any) => {
  switch (billing.state) {
    case STATES.SEND:
      return "info";
    case STATES.RETURNED:
      return "warning";
    case STATES.APPROVED:
      return "success";
    case STATES.REJECTED:
      return "error";
    default:
      return "default";
  }
};

export const UPLOAD_FILES_OPERATION = {
  SUPPORT: "support",
  MAIN: "main",
};
