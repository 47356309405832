import gql from "graphql-tag";

export const REINCARNATION = gql`
  query reincarnation {
    reincarnations {
      id
    }
  }
`;

export const REINCARNATION_CREATE = gql`
  mutation reincarnationCreate($input: ReincarnationCreateInput!) {
    reincarnationCreate(input: $input) {
      id
    }
  }
`;

export const REINCARNATION_UPDATE = gql`
  mutation reincarnationUpdate($input: ReincarnationCreateInput!) {
    reincarnationCreate(input: $input) {
      id
    }
  }
`;

export const EMPLOYMENT_CHANGE_MARITAL_STATUS_UPDATE = gql`
  mutation employmentChangeMaritalStatusUpdate(
    $input: EmploymentChangeMaritalStatusUpdateInput!
  ) {
    employmentChangeMaritalStatusUpdate(input: $input) {
      id
      createdAt
      maritalStatus
      changedName
      # applyFrom
      state
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
