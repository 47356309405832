import gql from "graphql-tag";

export const GET_BILLING_PREVIEW = gql`
  query billingPreview(
    $accessToken: String!
    $billingToken: String!
    $companyId: String!
  ) {
    billingPreview(
      accessToken: $accessToken
      billingToken: $billingToken
      companyId: $companyId
    ) {
      name
      state
      sum
      currency
      createdAt
      updatedAt
      isSplitBilling
      isSplitBySupportedFiles
      employeeId
      createdByUserId
      billingId
      message
      year
      reimbursed_amount
      splits {
        splitId
        amount
        amountType
        amountMoney
        costType
        department
        budget
        contract
        updatedAt
      }
      billingSupportFiles
      receipts {
        updatedAt
        issueDate
        splits {
          splitId
          amount
          amountType
          amountMoney
          costType
          department
          budget
          contract
          updatedAt
        }
        receiptFiles
        receiptSupportFiles
        sum
        scapiId
        state
        note
        recommendedCostType
        isSplitBySupportedFiles
        supplier
        vat
        receiptId
        receiptRef
      }
    }
  }
`;
