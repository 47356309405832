import { Grid, Typography } from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import SplitForm from "../SplitForm";
import ReceiptSplitControl from "./ReceiptSplitControl";

const ReceiptSplit = () => {
  const receipt = useBoundStore((state) => state.receipt);
  const updateSplit = useBoundStore((state) => state.updateSplit);
  const removeSplit = useBoundStore((state) => state.removeSplit);
  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);

  return (
    <Grid container spacing={2} sx={{ marginBottom: "60px" }}>
      <Grid item xs={12}>
        <Typography variant="h2">
          Do jakého rozpočtu spadá tvůj nákup?
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        Zbývá k vyúčtování: {receipt.sum} Kč
      </Grid> */}
      <Grid item xs={12}>
        <SplitForm
          splits={receipt.splits}
          removeSplit={removeSplit}
          updateSplit={updateSplit}
          sumAmount={receipt.sum}
          isDisabled={!isReceiptEditable}
        />
      </Grid>
      <Grid item xs={12}>
        <ReceiptSplitControl />
      </Grid>
    </Grid>
  );
};

export default ReceiptSplit;
