import { SplitType } from "../../../../types/SplitType";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SplitEntry from "./SplitEntry";
import {
  SPLIT_AMOUNT_TYPE,
  getCalculatedSplitAmount,
} from "../../../../utils/split";
import { CalculatedAmountType } from "../../../../types/CalculatedAmountType";
import { roundTwoDecimals } from "../../../../utils/number";
import { getCurrency, replaceDotToComma } from "../../../../utils/strings";

interface SplitFormProps {
  splits: SplitType[];
  removeSplit: (splitIndex: number) => void;
  updateSplit: (
    splitIndex: number,
    key: keyof SplitType,
    value: string | number
  ) => void;
  sumAmount: number;
  isDisabled: boolean;
}

// boxik na pridavani splitu a souboru, filtery atp
const SplitForm = ({
  splits,
  sumAmount,
  removeSplit,
  updateSplit,
  isDisabled,
}: SplitFormProps) => {
  const billing = useBoundStore((state) => state.billing);

  const [splitSumAmount, setSplitSumAmount] = useState(0);
  const [calculatedAmounts, setCalculatedAmounts] = useState<
    CalculatedAmountType[]
  >([]);

  useEffect(() => {
    if (splits) {
      let calculatedAmountSum = 0;
      let calculatedAmounts: CalculatedAmountType[] = [];

      let starSplitIndex = splits.findIndex(
        (splitItem) => splitItem.amountType === SPLIT_AMOUNT_TYPE.STAR
      );

      splits.forEach((splitItem, index) => {
        const calculatedAmount = getCalculatedSplitAmount(
          splitItem.amountType,
          splitItem.amount,
          sumAmount
        );

        if (starSplitIndex > -1) {
          calculatedAmounts.push({
            isStarDisabled: starSplitIndex === index ? false : true,
            amount: calculatedAmount,
          });
        } else {
          calculatedAmounts.push({
            isStarDisabled: false,
            amount: calculatedAmount,
          });
        }

        calculatedAmountSum += calculatedAmount;
      });

      //JS float precision fix
      calculatedAmountSum = parseFloat(calculatedAmountSum.toFixed(2));

      if (starSplitIndex > -1) {
        if (sumAmount - calculatedAmountSum < 0) {
          calculatedAmounts[starSplitIndex].amount = 0;
          setSplitSumAmount(calculatedAmountSum);
        } else {
          //ideal case of star without validatoin errors
          calculatedAmounts[starSplitIndex].amount =
            sumAmount - calculatedAmountSum;
          setSplitSumAmount(sumAmount);
        }
      } else {
        setSplitSumAmount(calculatedAmountSum);
      }

      setCalculatedAmounts(calculatedAmounts);
    }
  }, [splits, sumAmount]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          Zbývá k vyúčtování:{" "}
          {replaceDotToComma(roundTwoDecimals(sumAmount - splitSumAmount))}{" "}
          {getCurrency(billing.currency)}
        </Grid>
        {splits.map((splitItem, index) => (
          <Grid item key={index} xs={12}>
            <SplitEntry
              split={splitItem}
              splitIndex={index}
              removeSplit={() => removeSplit(index)}
              updateSplit={updateSplit}
              isStarDisabled={calculatedAmounts[index]?.isStarDisabled || false}
              calculatedAmount={calculatedAmounts[index]?.amount || 0}
              isDisabled={isDisabled}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SplitForm;
