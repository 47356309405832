import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { useMutation } from "@apollo/client";
import { DELETE_RECEIPT } from "./gql";
import { useParams } from "react-router-dom";
import { ReceiptType } from "../../../../types/ReceiptType";
import ReceiptEntry from "./ReceiptEntry";
import ReceiptSplashScreen from "./ReceiptSplashScreen";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FileUpload from "../../../../components/FileUpload";
import { GET_BILLING } from "../BillingForm/gql";
import { STATES } from "../../../../utils/billing";
import { useEffect, useState } from "react";
import { validateReceipt } from "./ReceiptValidation";
import { loadFromStorage, saveToStorage } from "../../../../utils/storage";

const RECEIPT_FILTER_KEY = "receipt-filter";

const Receipt = () => {
  const { billingId } = useParams();

  const theme = useTheme();
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up("md"));

  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const setAppLoading = useBoundStore((state) => state.setAppLoading);
  const setReceiptFormAction = useBoundStore(
    (state) => state.setReceiptFormAction
  );
  const setIsReceiptFormOpen = useBoundStore(
    (state) => state.setIsReceiptFormOpen
  );

  const resetReceipt = useBoundStore((state) => state.resetReceipt);
  const updateBilling = useBoundStore((state) => state.updateBilling);

  const addFileInput = useBoundStore((state) => state.addFileInput);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isBillingReturned = useBoundStore((state) => state.isBillingReturned);

  const billingSplitType = useBoundStore((state) => state.billingSplitType);

  const billing = useBoundStore((state) => state.billing);

  const [isReceiptFilter, setReceiptFilter] = useState(
    loadFromStorage(RECEIPT_FILTER_KEY) || false
  );

  const [deleteReceipt, { loading: deleteReceiptLoading }] = useMutation(
    DELETE_RECEIPT,
    {
      update(cache, { data: { deleteReceipt } }) {
        if (billingId) {
          let data: any = cache.readQuery({
            query: GET_BILLING,
            variables: {
              accessToken: selectedToken,
              billingId: parseInt(billingId),
            },
          });

          let currentReceipts: ReceiptType[] = JSON.parse(
            JSON.stringify(data.billing.receipts)
          );

          const indexToDelete = currentReceipts.findIndex(
            (receiptItem) => receiptItem.receiptId === deleteReceipt
          );

          if (indexToDelete > -1) {
            currentReceipts.splice(indexToDelete, 1);
          }

          updateBilling("receipts", currentReceipts);

          let newBillingData = JSON.parse(JSON.stringify(data.billing));

          newBillingData["receipts"] = currentReceipts;

          cache.writeQuery({
            query: GET_BILLING,
            variables: {
              accessToken: selectedToken,
              billingId: parseInt(billingId),
            },
            data: {
              billing: newBillingData,
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (billing.state === STATES.RETURNED) {
      setReceiptFilter(true);
    }
  }, [billing.state]);

  const handleDeleteReceipt = (receiptId: number) => {
    setAppLoading(true);
    deleteReceipt({
      variables: {
        accessToken: selectedToken,
        receiptId: receiptId,
      },
      onError(error) {
        console.log(error.message);
        setAppLoading(false);
        openPopupMessage(error?.message, "error");
      },
      onCompleted(data) {
        console.log(data);
        setAppLoading(false);
      },
    });
  };

  const handleUploadFiles = (files: File[]) => {
    files.forEach((fileItem) => addFileInput({ file: fileItem }));

    resetReceipt();
    setReceiptFormAction("newUploadFile");
    setIsReceiptFormOpen(true);
  };

  const sortReceipts = (receiptA: ReceiptType, receiptB: ReceiptType) => {
    if (
      billing.state !== STATES.RETURNED &&
      receiptA.receiptRef &&
      receiptB.receiptRef
    ) {
      return receiptA?.receiptRef - receiptB.receiptRef;
    } else {
      if (
        receiptA.state === STATES.RETURNED &&
        (receiptB.state === STATES.SEND || receiptB.state === STATES.INPROGRESS)
      ) {
        return -1;
      } else if (
        (receiptA.state === STATES.SEND ||
          receiptA.state === STATES.INPROGRESS) &&
        receiptB.state === STATES.RETURNED
      ) {
        return 1;
      } else {
        if (receiptA.receiptRef && receiptB.receiptRef)
          return receiptA?.receiptRef - receiptB.receiptRef;
      }
    }

    return 0;
  };

  const filterReceipt = (receipt: ReceiptType) => {
    if (isReceiptFilter && !isBillingDisabled) {
      if (receipt.state === STATES.RETURNED) return true;
      return !validateReceipt(receipt, billingSplitType).isValid;
    } else {
      return true;
    }
  };

  const handleReceiptFilterChange = (isFiltering: boolean) => {
    setReceiptFilter(isFiltering);
    saveToStorage(RECEIPT_FILTER_KEY, isFiltering);
  };

  return (
    <>
      <Grid container spacing={2}>
        {billing?.receipts?.length === 0 ? (
          <Grid item xs={12}>
            <ReceiptSplashScreen />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h2">Účtenky</Typography>
              </Grid>

              {!isBillingDisabled && (
                <Grid item>
                  <FormControlLabel
                    data-testid="receipt-filter-switch"
                    control={
                      <Switch
                        size="small"
                        checked={isReceiptFilter}
                        onChange={(e) =>
                          handleReceiptFilterChange(e.target.checked)
                        }
                      />
                    }
                    label="Pouze k řešení"
                  />
                </Grid>
              )}
            </Grid>

            {!isBillingDisabled && !isBillingReturned && (
              <Grid item container spacing={2}>
                {!isBreakpointMd && (
                  <Grid item xs={6} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disableElevation
                      onClick={() => {
                        setReceiptFormAction("newTakePhoto");
                        setIsReceiptFormOpen(true);
                      }}
                      startIcon={<AddAPhotoIcon />}
                    >
                      Vyfotit
                    </Button>
                  </Grid>
                )}

                <Grid item xs={6} md={12} textAlign="center">
                  <FileUpload
                    handleUploadFiles={handleUploadFiles}
                    multiple={false}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item container spacing={1}>
              {billing &&
                billing.receipts &&
                [...billing.receipts]
                  .filter(filterReceipt)
                  ?.sort(sortReceipts)
                  .map((receiptItem: ReceiptType) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={receiptItem.receiptId}
                        data-testid="receipt-entry"
                      >
                        <ReceiptEntry
                          receipt={receiptItem}
                          deleteReceipt={() =>
                            handleDeleteReceipt(receiptItem.receiptId!)
                          }
                          isDeleteLoading={deleteReceiptLoading}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Receipt;
