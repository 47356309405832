import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { BillingSendActionType } from "../../../../types/BillingSendActionType";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { getCurrency, replaceDotToComma } from "../../../../utils/strings";
import { roundTwoDecimals } from "../../../../utils/number";

interface BillingControlProps {
  sendBilling: (action: BillingSendActionType) => void;
  isSendBillingLoading: boolean;
}

const BillingControl = ({
  sendBilling,
  isSendBillingLoading,
}: BillingControlProps) => {
  const billing = useBoundStore((state) => state.billing);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);

  const theme = useTheme();

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0, background: "white" }}
      >
        <Toolbar>
          <Container
            maxWidth="md"
            disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item sx={{ flex: 1, color: "black" }}>
                <Typography sx={{ fontSize: "0.9em" }}>Celkem:</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {replaceDotToComma(
                    roundTwoDecimals(
                      billing.receipts.reduce(
                        (prev, curr) => prev + curr.sum,
                        0
                      )
                    )
                  )}{" "}
                  {getCurrency(billing.currency)}
                </Typography>
              </Grid>
              {!isBillingDisabled && (
                <>
                  <Grid item>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      disableElevation
                      color="secondary"
                      startIcon={<SaveIcon />}
                      onClick={() => sendBilling("save")}
                      loading={isSendBillingLoading}
                    >
                      Uložit
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      disableElevation
                      startIcon={<SendIcon />}
                      onClick={() => sendBilling("send")}
                      loading={isSendBillingLoading}
                    >
                      Odeslat
                    </LoadingButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default BillingControl;
