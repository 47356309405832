import { Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import { ReceiptFormErrorType } from "../../../../types/ReceiptFormErrorType";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";

interface ReceiptInfoProps {
  next: () => void;
  save: () => void;
  isSaveLoading: boolean;
  isReadDocumentAiLoading: boolean;
  errors: ReceiptFormErrorType;
}

const ReceiptInfo = ({
  next,
  save,
  isSaveLoading,
  errors,
  isReadDocumentAiLoading,
}: ReceiptInfoProps) => {
  const receipt = useBoundStore((state) => state.receipt);
  const updateReceipt = useBoundStore((state) => state.updateReceipt);
  const billingSplitType = useBoundStore((state) => state.billingSplitType);
  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);

  const handleNext = () => {
    if (billingSplitType === "splitReceipt") {
      next();
    } else {
      save();
    }
  };

  const handleIssueDateChange = (newDate: Moment | null) => {
    if (moment(newDate).isValid()) {
      updateReceipt("issueDate", moment(newDate).format("YYYY-MM-DD"));
    } else {
      updateReceipt("issueDate", "");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Informace o nákupu</Typography>
      </Grid>

      {isReadDocumentAiLoading && (
        <Grid item xs={12}>
          Probíhá automatické zpracování
          <LinearProgress />
          {/* <CircularProgress sx={{ height: "12px" }} /> */}
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="number"
          label="Částka"
          id="receipt-sum"
          // defaultValue="400"
          variant="outlined"
          size="small"
          value={receipt.sum}
          onChange={(e) => updateReceipt("sum", parseFloat(e.target.value))}
          disabled={
            isBillingDisabled || isReadDocumentAiLoading || !isReceiptEditable
          }
          helperText={"sum" in errors ? errors.sum : ""}
          error={"sum" in errors}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Datum plnění"
          format="DD.MM.YYYY"
          disabled={
            isBillingDisabled || isReadDocumentAiLoading || !isReceiptEditable
          }
          // error={"issueDate" in errors}
          slotProps={{
            textField: {
              id: "receipt-issue-date",
              size: "small",
              required: true,
              variant: "outlined",
              fullWidth: true,
              error: "issueDate" in errors,
              helperText: "issueDate" in errors ? errors.issueDate : "",
              // helperText:{"issueDate" in errors? errors.sum: ""},
              // error: touched.issueDate && Boolean(errors.issueDate),
              // helperText: touched.issueDate && errors.issueDate,
            },
          }}
          value={moment(receipt.issueDate)}
          // value={ moment(billi["issueDate"]).isValid()
          //     ? moment(values["issueDate"])
          //     : null
          // }
          onChange={(newDate) => handleIssueDateChange(newDate)}
        />
        {/* <TextField
          fullWidth
          label="Datum"
          id="receipt-issue-date"
          // defaultValue="12.2.2024"
          variant="outlined"
          size="small"
          onChange={(e) => updateReceipt("issueDate", e.target.value)}
          value={receipt.issueDate}
          disabled={isBillingDisabled}
        /> */}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="Poznámka"
          variant="outlined"
          placeholder="(Detailní popis, v případě občerstvení uvést název klienta (ičo) nebo informaci, že jde o občerstvení s kolegy, teambuilding. V případě spropitného, zadej částku včetně spropitného. Doklad o spropitném vlož samostatně do sekce Podklady)"
          size="small"
          multiline
          rows={4}
          value={receipt.note}
          onChange={(e) => updateReceipt("note", e.target.value)}
          disabled={isBillingDisabled || !isReceiptEditable}
          helperText={"note" in errors ? errors.note : ""}
          error={"note" in errors}
        />
      </Grid>
      {(!isBillingDisabled ||
        billingSplitType === "splitReceipt" ||
        isReceiptEditable) && (
        <Grid item xs={12}>
          <LoadingButton
            disabled={isReadDocumentAiLoading}
            variant="contained"
            disableElevation
            fullWidth
            onClick={handleNext}
            loading={isSaveLoading}
            startIcon={
              billingSplitType === "splitReceipt" ? (
                <ArrowForwardIcon />
              ) : (
                <SaveIcon />
              )
            }
          >
            {billingSplitType === "splitReceipt" ? "Pokračovat" : "Uložit"}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ReceiptInfo;
