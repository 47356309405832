//Sekce pro nahrani souboru k Vyuctovani, ktera je tam vzdy pod uctenkama

import { Box, Typography } from "@mui/material";
import SupportFile from "../SupportFile";
import { Dispatch, SetStateAction } from "react";
import { SupportFileType } from "../../../../types/SupportFileType";
import { useBoundStore } from "../../../../utils/stores/BoundStore";

interface BillingSupportFileProps {
  supportFiles: SupportFileType[];
  setSupportFile: Dispatch<SetStateAction<SupportFileType[]>>;
  setSupportFileDelete: Dispatch<SetStateAction<number[]>>;
  uploadedFiles: number[];
  supportFilesDelete: number[];
}

const BillingSupportFile = ({
  supportFiles,
  setSupportFile,
  uploadedFiles,
  setSupportFileDelete,
  supportFilesDelete,
}: BillingSupportFileProps) => {
  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  return (
    <Box mb={10}>
      <Typography variant="h2">Podklady</Typography>
      <SupportFile
        setSupportFile={setSupportFile}
        setSupportFileDelete={setSupportFileDelete}
        supportFiles={supportFiles}
        uploadedFiles={uploadedFiles}
        supportFilesDelete={supportFilesDelete}
        isDisabled={isBillingDisabled}
      />
    </Box>
  );
};

export default BillingSupportFile;
