import { ReceiptFormErrorType } from "../../../../types/ReceiptFormErrorType";
import { ReceiptType } from "../../../../types/ReceiptType";

export const validateForm = (
  receipt: ReceiptType,
  activeStep: number
): { isValid: boolean; errors: ReceiptFormErrorType } => {
  let errors: ReceiptFormErrorType = {};

  switch (activeStep) {
    case 0: {
      if (!receipt.sum) {
        errors.sum = "Suma musí být vyplněna";
      }

      if (!receipt.issueDate) {
        errors.issueDate = "Datum musí být vyplněno";
      }

      if (!receipt.note) {
        errors.note = "Poznámka musí být vyplněna";
      }
      break;
    }
    //FIXME: in case of need use this for validation the SPLIT step
    // case 1: {
    //   if (receipt.splits.length === 0) {
    //     errors.push("Musí být přidaný alespoň jeden rozpočet");
    //     break;
    //   }

    //   //check each split
    //   receipt.splits.forEach((splitItem) => {});

    //   break;
    // }
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};
