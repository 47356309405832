import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";

const HelpBillingCycle = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">
              Životní cyklus vyúčtování
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Životní cyklus vyúčtování</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="stavy">Stavy a jejich význam</Typography>

              <Typography fontSize={"1.2em"}>Nové</Typography>

              <p>
                Po založení vyúčtování a dokud nedojde k jeho odeslání. Je možné
                editovat veškerý obsah.
              </p>

              <Typography fontSize={"1.2em"}>Odesláno</Typography>

              <p>
                Po odeslání vyúčtování. V případě prvního odeslání jde požadavek
                na schválení do Neznámu. Pokud již bylo vyúčtování vráceno k
                opravě odesílá se zpět k účetní.
              </p>

              <Typography fontSize={"1.2em"}>Zamítnuto</Typography>

              <p>
                Nadřízený zamítnul vyúčtování při zpracování požadavku v Neznámu
                nebo účetní zamítla vyúčtování při jeho zpracování. S
                vyúčtováním již nelze dále pracovat.
              </p>

              <Typography fontSize={"1.2em"}>Vráceno</Typography>

              <p>
                Nadřízený vrátil vyúčtování k opravě při zpracování požadavku v
                Neznámu nebo účetní vrátila vyúčtování k opravě při jeho
                zpracování.
              </p>

              <p>
                V detailu vyúčtování se objeví poznámka s odůvodněním vrácení.
                Problematické účtenky jsou označené oranžovou ikonou s
                otazníkem. Je možné editovat účtenky a rozpočty.
              </p>

              <p>Odešle se email vlastníkovi vyúčtování.</p>

              <Typography fontSize={"1.2em"}>Schváleno</Typography>

              <p>Nadřízený schválil vyúčtování a čeká na zpracování účetní.</p>

              <Typography fontSize={"1.2em"}>Ve zpracování</Typography>

              <p>
                Účetní přijala schválené nebo opravené vyúčtování a nyní jej
                zpracovává.
              </p>

              <Typography fontSize={"1.2em"}>K proplacení</Typography>

              <p>Byl vytvořen platební příkaz.</p>

              <Typography fontSize={"1.2em"}>Proplaceno</Typography>

              <p>
                V bankovním výpisu je informace o zaúčtování. Odešle se email
                vlastníkovi vyúčtování.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(1) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#stavy">
                    <ListItemText primary="Stavy" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpBillingCycle;
