import { Box } from "@mui/material";
import { CS_STATES } from "../../../../utils/billing";
import { green } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { indigo } from "@mui/material/colors";

interface BillingOverviewEntryStateProps {
  state: string;
}
const BillingOverviewEntryState = ({
  state,
}: BillingOverviewEntryStateProps) => {
  const STATE_BACKGROUNDS: {
    [key: string]: { color: string; background: string };
  } = {
    N: { background: indigo[300], color: "#ffffff" },
    S: { background: "none", color: "#505050" },
    V: { background: "#fb8c00", color: "#ffffff" },
    A: { background: "none", color: "#43a047" },
    I: { background: "none", color: green[600] },
    O: { background: "none", color: blue[800] },
  };

  return (
    <Box
      sx={{
        background: STATE_BACKGROUNDS[state]?.background || "#dddddd",
        display: "inline",
        padding: "2px 4px",
        borderRadius: "6px",
        color: STATE_BACKGROUNDS[state]?.color || "#000000",
      }}
    >
      {CS_STATES[state]}
    </Box>
  );
};

export default BillingOverviewEntryState;
