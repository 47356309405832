import { BillingSplitType } from "../../../../types/BillingSplitType";
import { ReceiptFormErrorType } from "../../../../types/ReceiptFormErrorType";
import { ReceiptType } from "../../../../types/ReceiptType";
import { SplitType } from "../../../../types/SplitType";
import {
  SPLIT_AMOUNT_TYPE,
  getCalculatedSplitAmount,
} from "../../../../utils/split";
import { validateSplit } from "../SplitForm/SplitValidation";

export const validateReceipt = (
  receipt: ReceiptType,
  billingSplitType: BillingSplitType
): { isValid: boolean; errors: ReceiptFormErrorType } => {
  let errors: ReceiptFormErrorType = {};

  if (!receipt.sum) {
    errors.sum = "Suma musí být vyplněna";
  }

  if (!receipt.issueDate) {
    errors.issueDate = "Datum musí být vyplněno";
  }

  if (!receipt.note) {
    errors.note = "Poznámka musí být vyplněna";
  }

  if (billingSplitType === "splitReceipt") {
    if (receipt.splits.length === 0) {
      errors.splits = "Musí být přidaný alespoň jeden rozpočet";
    } else {
      let sumSplitAmount = 0;
      let hasStarType = false;
      receipt.splits.forEach((splitItem: SplitType, index) => {
        sumSplitAmount += getCalculatedSplitAmount(
          splitItem.amountType,
          splitItem.amount,
          receipt.sum
        );
        if (splitItem.amountType === SPLIT_AMOUNT_TYPE.STAR) hasStarType = true;

        const splitValidationResult = validateSplit(splitItem);
        if (!splitValidationResult.isValid) {
          errors.splits = "Chyba v rozpočtech";
        }
      });

      //JS float precision fix
      sumSplitAmount = parseFloat(sumSplitAmount.toFixed(2));

      if (hasStarType) {
        if (receipt.sum < sumSplitAmount) {
          errors.splits = "Částky u rozpočtů nejsou vyplněny správně";
        }
      } else {
        if (receipt.sum - sumSplitAmount !== 0) {
          errors.splits = "Zbývající částka k vyúčtování musí být rovna 0";
        }
      }
    }
  }
  // console.log(errors);

  return { isValid: Object.keys(errors).length === 0, errors };
};
