import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";
import NewBilling from "../../../images/help/new_billing.gif";
import MainInfoBilling from "../../../images/help/main_info_billing.gif";
import AddReceipt from "../../../images/help/add_receipt.png";
import InfoReceipt from "../../../images/help/info_receipt.gif";
import InfoSplit from "../../../images/help/info_split.gif";
import AddSourceFile from "../../../images/help/add_source_file.png";
import ReceiptList from "../../../images/help/receipt_list.png";
import AddReceipt2 from "../../../images/help/add_receipt2.png";
import AddSplitBilling from "../../../images/help/add_split_billing.gif";
import AddBillingSourceFile from "../../../images/help/add_billing_source_file.png";
import SaveSendBilling from "../../../images/help/save_send_billing.png";
import EditBilling from "../../../images/help/edit_billing.gif";
import DeleteBilling from "../../../images/help/delete_billing.gif";

const HelpBillingAdmin = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">Správa vyúčtování</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Správa vyúčtování</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="nez_zacnete">Než začnete</Typography>

              <p>
                Podle aktualizované směrnice Pokladna je nutné předávat
                vyúčtování k proplacení minimálně jednou měsíčně. Aplikace
                Carmen umožňuje ukládání účtenek do jednoho rozpracovaného
                vyúčtování, takže můžete účtenky postupně přidávat během měsíce.
                Pokud v daném měsíci máte pouze jednu účtenku nebo více účtenek
                v hodnotě do 100 Kč včetně DPH, můžete je s ohledem na směrnici
                přidat do vyúčtování za následující měsíc, kdy celkové výdaje
                překročí 100 Kč včetně DPH.
              </p>

              <Typography fontSize={"1.5em"} id="postup_vytvoreni">Postup vytvoření</Typography>

              <Typography fontSize={"1.2em"}>Vytvoření vyúčtování</Typography>

              <img width="100%" src={NewBilling} alt="nové vyúčtování" />

              <p>
                Na hlavní stránce naleznete tlačítko “Nové vyúčtování”.
                Kliknutím na něj zahájíte proces vytváření nového vyúčtování.
              </p>

              <Typography fontSize={"1.2em"}>
                Vyplnění základních údajů
              </Typography>

              <img width="100%" src={MainInfoBilling} alt="nové vyúčtování" />

              <p>
                Zadejte nový název vyúčtování nebo ponechte přednastavený. Název
                by měl co nejlépe odpovídat obsahu vyúčtování, například
                "Cestovní náklady - konference Praha" nebo "Teambuilding
                červen". To Vám, schvalovatelům a účetní usnadní identifikaci
                vyúčtování.
              </p>

              <p>
                Momentálně není možné zpracovávat vyúčtování v jiné než tuzemské
                měně.
              </p>

              <p>Vyberte rok, do kterého spadají doklady.</p>

              <p>
                Pro usnadnění zadávání údajů z účtenek ponechte zaškrtnutou
                možnost "Automatické zpracování účtenek". Tato funkce umožňuje
                aplikaci automaticky rozpoznat a vyplnit informace z nahraných
                účtenek, což šetří čas a snižuje riziko chyb.
              </p>

              <p>Existují dvě možnosti zadání rozpočtu.</p>

              <p>
                Pokud jsou vaše <b>účtenky různého typu</b> a jsou čerpány z
                různých rozpočtů tak zvolte variantu{" "}
                <b>“Rozpočty k účtenkám”</b>. Například kombinace účtenek za
                parkování a oběd s klientem.
              </p>

              <p>
                Pokud jsou vaše <b>účtenky shodného typu</b> a čerpány ze
                stejného rozpočtu zvolte variantu <b>“Rozpočty k vyúčtování”</b>
                . Například pouze parkování za celý měsíc.
              </p>

              <p>Nakonec nastavení vyúčtování uložíme.</p>

              <Typography fontSize={"1.5em"} id="pridani_uctenky">Přidání účtenky</Typography>

              <img width="100%" src={AddReceipt} alt="přidání účtenky" />

              <p>
                Existují dvě možnosti, jak přidat účtenky: vyfocení pomocí mobilu
                nebo nahrání souboru.
              </p>

              <p>
                Při focení můžete být vyzváni k povolení přístupu k fotoaparátu.
                Je důležité vyfotit účtenku tak aby byla dobře nasvícená a
                všechny údaje byly čitelné. Nahrát můžete základní typy souborů
                jako jsou obrázky ve formátu JPG, PNG a dokumenty PDF. Opět
                prosím dbejte na kvalitu, pokud jde například o naskenované
                dokumenty.
              </p>

              <Typography fontSize={"1.2em"}>Informace o nákupu</Typography>

              <img
                width="100%"
                src={InfoReceipt}
                alt="přidání informací o nákupu"
              />

              <p>
                Po nahrání nebo vyfocení účtenky je potřeba vyplnit základní
                informace.
              </p>

              <p>
                Částka představuje celkovou sumu, která byla zaplacena a to včetně
                spropitného. Pokud bude toto pole automaticky vyplněné je
                důležité zkontrolovat jeho správnost.
              </p>

              <p>
                Datum plnění je den, kdy došlo k uskutečnění nákupu. Opět v
                případě automatického vyplnění prosím proveďte kontrolu.
              </p>

              <p>
                Do pole poznámky napište detailní popis. Měl by obsahovat co
                bylo předmětem nákupu, jeho účel a případně identifikaci
                případného klienta.
              </p>

              <Typography fontSize={"1.2em"}>Rozpočty</Typography>

              <img
                width="100%"
                src={InfoSplit}
                alt="přidání informací o rozpočtu"
              />

              <p>
                Pokud byla zvolena možnost “Rozpočty k účtenkám”, zobrazí se
                možnost nastavit rozpočty k dané účtence.
              </p>

              <p>
                Pro nastavení hodnoty rozpočtu slouží pole “Částka”. Vedle něj
                je možnost změnit typ hodnoty na pevnou částku, procento nebo
                automatický dopočet. Tento přepínač umožňuje flexibilní práci s
                rozpočítáním celkové částky mezi různé kombinace rozpočtů,
                útvarů a zakázek, viz níže.
              </p>

              <p>
                Dalším krokem je výběr rozpočtu, nákladového střediska a
                zakázky. Políčka pro výběr zároveň slouží jako filtry. Například
                po výběru konkrétního nákladového střediska se pole pro rozpočet
                a zakázku omezí na možnosti, které odpovídají vybranému
                nákladovému středisku. Tento filtr je zobrazený v podobě bublinky
                vedle textu “Použité filtry:” a je možné ho zde odstranit. Pokud
                určitá kombinace filtru neumožňuje výběr z více než jedné
                možnosti dojde k automatickému vyplnění. V průběhu používání
                aplikace se bude výběr automaticky řadit dle četnosti používání.
              </p>

              <p>
                Pokud budete potřebovat přidat další kombinaci, stačí kliknout
                na tlačítko “+ Rozpočet”.
              </p>

              <p>
                V horní části se nachází zbývající částka, kterou je potřeba
                přiřadit k rozpočtům.
              </p>

              <Typography fontSize={"1.2em"}>Podklady</Typography>

              <img width="100%" src={AddSourceFile} alt="přidání podkladů" />

              <p>
                Zde můžete nahrát další soubory, které mohou být nutné pro
                schválení a proplacení vyúčtování. Jedná se například o
                potvrzení platby z platebního terminálu (z důvodu proplacení
                spropitného), pozvánka na událost, potvrzení účasti na školení a
                případně další fotodokumentace.
              </p>

              <Typography fontSize={"1.5em"} id="sprava_uctenek">
                Přidání dalších účtenek, jejich úprava a mazání
              </Typography>

              <img width="100%" src={ReceiptList} alt="seznam účtenek" />

              <p>
                Po přidání první účtenky se zobrazí přehled celého vyúčtování.
                Zde je možné vyfotit nebo nahrát další účtenky a postupovat od
                části “Informace o nákupu”.
              </p>

              <p>
                Již existující účtenky můžete upravit (ikona tužky) nebo smazat
                (ikona popelnice).
              </p>

              <p>
                Pokud je na účtence nějaký problém (chybějící data u rozpočtů,
                nepřiřazená částka k rozpočtu) tak se zobrazí oranžový
                trojúhelník.
              </p>

              <Typography fontSize={"1.5em"} id="rozpocet_vyuctovani">
                Varianta rozpočtu k vyúčtování
              </Typography>

              <Typography fontSize={"1.2em"}>Přidání účtenky</Typography>

              <img width="100%" src={AddReceipt2} alt="přidání účtenky" />

              <p>
                Je potřeba vyplnit stejné základní údaje, jak je popsáno v části
                “Informace o nákupu”. Pak už jen stačí účtenku uložit.
              </p>

              <Typography fontSize={"1.2em"}>Přidání rozpočtů</Typography>

              <img width="100%" src={AddSplitBilling} alt="seznam účtenek" />

              <p>
                Na detailu vyúčtování je pod části se seznamem účtenek seznam
                rozpočtů. Stejně jako v části “Rozpočty” se nastaví potřebné
                informace a případně přidají další rozpočty. V této variantě se
                přiřazuje celá částka za všechny účtenky.
              </p>

              <p>
                Je důležité po přidání rozpočtů vyúčtování uložit tlačítkem ve
                spodní části.
              </p>

              <Typography fontSize={"1.5em"} id="podklady">
                Přidání podkladů k vyúčtování
              </Typography>

              <img
                width="100%"
                src={AddBillingSourceFile}
                alt="přidání podkladů k vyúčtování"
              />

              <p>
                Na detailu vyúčtování pod seznamem účtenek (a seznamem rozpočtů)
                je možné nahrát podkladové soubory k celému vyúčtování.
              </p>

              <Typography fontSize={"1.5em"} id="ulozeni">Uložení vyúčtování</Typography>

              <img
                width="100%"
                src={SaveSendBilling}
                alt="uložení vyúčtování"
              />

              <p>
                Stejně jako při práci s účtenkami je potřeba změny provedené na
                detailu vyúčtování ukládat. Týká se to přidávání rozpočtů k
                vyúčtování (režim “Rozpočty k vyúčtování”) a nahrávání podkladů
                k vyúčtování.
              </p>

              <p>
                Tlačítko “Uložit” naleznete ve spodní části vpravo v detailu
                vyúčtování vedle tlačítka “Odeslat”.
              </p>

              <Typography fontSize={"1.5em"} id="nastaveni">Nastavení vyúčtování</Typography>

              <img width="100%" src={EditBilling} alt="úprava vyúčtování" />

              <p>
                Pro úpravu základního nastavení vyúčtování z části “Vyplnění
                základních údajů” se můžete dostat skrze ozubené kolečko v horní
                části detailu vyúčtování.
              </p>

              <Typography fontSize={"1.5em"} id="smazani">Smazání vyúčtování</Typography>

              <img width="100%" src={DeleteBilling} alt="smazání vyúčtování" />

              <p>
                Možnost smazání naleznete v horní části nastavení vyúčtování v
                podobě ikonky popelnice.
              </p>

              <Typography fontSize={"1.5em"} id="odeslani">
                Kontrola vyúčtování a odeslání
              </Typography>

              <img
                width="100%"
                src={SaveSendBilling}
                alt="odeslání vyúčtování"
              />

              <p>
                Jakmile máte celé vyúčtování nachystané, tj. nahrané všechny
                účtenky a vyplněné rozpočty, můžete vyúčtování odeslat svému
                nadřízenému ke schválení do Neznámu.
              </p>

              <p>
                K tomu slouží tlačítko vpravo ve spodní části detailu vyúčtování
                “Odeslat”.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(1) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#nez_zacnete">
                    <ListItemText primary="Než začnete" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#postup_vytvoreni">
                    <ListItemText primary="Postup vytvoření" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#pridani_uctenky">
                    <ListItemText primary="Přidání účtenky" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#sprava_uctenek">
                    <ListItemText primary="Správa účtenek" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#rozpocet_vyuctovani">
                    <ListItemText primary="Rozpočet k vyúčtování" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#podklady">
                    <ListItemText primary="Podklady" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#ulozeni">
                    <ListItemText primary="Uložení" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#nastaveni">
                    <ListItemText primary="Nastavení" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#smazani">
                    <ListItemText primary="Smazání" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#odeslani">
                    <ListItemText primary="Odeslání" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpBillingAdmin;
