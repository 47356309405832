import { gql } from "@apollo/client";

export const USER = gql`
  query {
    me {
      username
      locale
      authorized
      roles {
        name
      }
    }
  }
`;

export const GET_USER_TOKENS = gql`
  query {
    tokens {
      accessToken
      uniqueFullName
      companyName
    }
  }
`;

export const GET_SPLIT_OPTIONS = gql`
  query splitOptions($accessToken: String!, $year: String!) {
    splitOptions(accessToken: $accessToken, year: $year) {
      contracts {
        label
        value
      }

      lookups {
        budget {
          label
          value
        }
        contract {
          label
          value
        }
        department {
          label
          value
        }
        costType {
          label
          value
        }
        usageProc
      }
    }
  }
`;
