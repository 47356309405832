import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";
import Introduction from "../../../images/login.png";
import LoginImage from "../../../images/help/login.gif";

const HelpIntroduction = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">Úvod do aplikace</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Úvod do aplikace</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="uvod">
                Úvod
              </Typography>

              <img width="100%" src={Introduction} alt="uvítací obrázek" />

              <p>
                Zapomeňte na papírové účtenky, tištěné tabulky a stohy dokumentů
                potřebných k proplacení vašich pracovních výdajů. S aplikací
                Carmen máte vše pod kontrolou online - kdykoliv a odkudkoliv, ať
                už jste na počítači, nebo na mobilu. Carmen vám šetří čas,
                zjednodušuje proces vyúčtování a zároveň přispívá k ochraně
                životního prostředí.
              </p>

              <p>
                Carmen umožňuje snadné vytváření nových vyúčtování, kam postupně
                přidáváte účtenky za firemní náklady hrazené z vlastních
                prostředků. K účtenkám vždy připojíte rozpočet, případné
                podklady a další potřebné údaje. Jedním
                kliknutím odešlete ke schválení nadřízenému, který váš požadavek
                uvidí v Centru požadavků v Neznámu, podobně jako žádosti o
                dovolenou.
              </p>

              <Typography fontSize={"1.2em"}>
                Klíčové vlastnosti aplikace
              </Typography>

              <p>
                <b>Přehledná správa vyúčtování:</b> Můžete snadno vytvářet
                vyúčtování, nahrávat účtenky a přiřazovat rozpočty, přičemž
                Carmen umí automaticky načítat základní údaje z účtenek, jako
                jsou datum nebo částka. Všechny údaje zkontrolovat kvůli
                přesnosti (<Link to={"/help/admin"} component={NavLink}>Správa vyúčtování</Link>).
              </p>

              <p>
                <b>Flexibilita:</b> Carmen funguje jak na počítači, tak na
                mobilních zařízeních, což znamená, že můžete začít vyúčtování na
                jednom zařízení a dokončit ho na jiném. Aplikace je tak
                přístupná a flexibilní bez ohledu na to, kde se nacházíte.
              </p>

              <p>
                <b>Schvalování:</b> Po odeslání vyúčtování nadřízenému se proces
                schválení odehrává v rámci Centra požadavků. Nadřízený zde může
                snadno přezkoumat vaše vyúčtování a rozhodnout o jeho schválení
                nebo zamítnutí (<Link to={"/help/approve"} component={NavLink}>Schvalování vyúčtování</Link>).
              </p>

              <p>
                <b>Snadné zpracování účetní:</b> Po schválení vyúčtování se
                automaticky vytvoří podkladní doklad, který účetní může snadno
                zpracovat. Carmen tak zjednodušuje celý proces od nahrání
                účtenky až po její zpracování v účetnictví.
              </p>

              <Typography fontSize={"1.5em"} id="smernice">
                Směrnici Pokladna
              </Typography>

              <p>
                S ohledem na aktualizovanou směrnici Pokladna je předkládejte
                vyúčtování minimálně jednou měsíčně. Carmen umožňuje ukládání
                účtenek do jednoho rozpracovaného vyúčtování, které můžete
                postupně doplňovat. Pokud máte za měsíc pouze jednu účtenku nebo
                účtenky <b>do 100 Kč včetně DPH</b>, můžete je vložit do
                vyúčtování následujícího měsíce, jakmile vaše výdaje přesáhnou
                tuto částku.
              </p>

              <p>
                Vyúčtování můžete tvořit i pro každou účtenku samostatně, ale s
                ohledem na pokyny uvedené v předcházejícím odstavci.
              </p>

              <Typography fontSize={"1.5em"} id="prihlaseni">Přihlášení</Typography>

              <img width="100%" src={LoginImage} alt="přihlášení" />

              <p>
                Pro přihlášení se používají Vaše standardní přihlašovací údaje,
                které používáte do ostatních systémů (Neznám, Chobotnice).
              </p>

              <p>
                Navštivte stránku <Link to={"/"} component={NavLink}>carmen.pszn.cz</Link> a klikněte na tlačítko
                "Přihlásit se". Poté vyplňte své přihlašovací údaje a přihlaste
                se.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(1) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#uvod">
                    <ListItemText primary="Úvod" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#smernice">
                    <ListItemText primary="Směrnice Pokladna" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#prihlaseni">
                    <ListItemText primary="Přihlášení" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpIntroduction;
