import { gql } from '@apollo/client';

export const GET_ENTRYFORM = gql`
  query {
    employmentEntryForm {
      state
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const REINCARNATION = gql`
  query reincarnation {
    reincarnation {
      id
    }
  }
`;

export const REINCARNATION_UPDATE = gql`
  mutation reincarnationUpdate(
    $input: ReincarnationUpdateInput!
  ) {
    reincarnationUpdate(input: $input) {
      id
    }
  }
`;