import {
  AppBar,
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ResponsiveFullScreenModal from "./layout/ResponsiveFullScreenModal";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { DocumentPreviewType } from "../types/DocumentPreviewType";
import { Document, Page } from "react-pdf";
import { useBoundStore } from "../utils/stores/BoundStore";
import FileUpload from "./FileUpload";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ReceiptType } from "../types/ReceiptType";
import DownloadIcon from "@mui/icons-material/Download";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { ImgZoomType } from "../types/ImgZoomType";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

interface DocumentPreviewModalProps {
  fileId: number;
  document: string;
  isOpen: boolean;
  documentPreviewType: DocumentPreviewType;
  setPreviewModalOpen: Dispatch<SetStateAction<boolean>>;
  close: () => void;
  receipt: ReceiptType | null;
  isReuploadDisabled: boolean;
}

const ZOOM_STEPS: { [key: number]: { zoomWidth: number; label: string } } = {
  1: { zoomWidth: 100, label: "1" },
  2: { zoomWidth: 125, label: "25" },
  3: { zoomWidth: 150, label: "50" },
  4: { zoomWidth: 175, label: "75" },
  5: { zoomWidth: 200, label: "100" },
};

const DocumentPreviewModal = ({
  document,
  isOpen,
  setPreviewModalOpen,
  close,
  documentPreviewType,
  receipt,
  fileId,
  isReuploadDisabled,
}: DocumentPreviewModalProps) => {
  const theme = useTheme();
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up("md"));

  const setReceipt = useBoundStore((state) => state.setReceipt);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);

  const setIsReceiptFormOpen = useBoundStore(
    (state) => state.setIsReceiptFormOpen
  );

  const setReceiptFormAction = useBoundStore(
    (state) => state.setReceiptFormAction
  );

  const resetReceipt = useBoundStore((state) => state.resetReceipt);

  const addFileInput = useBoundStore((state) => state.addFileInput);

  const [imgWidth, setImgWidth] = useState<number>(100);
  const [zoomStep, setZoomStep] = useState<number>(1);

  useEffect(() => {
    setImgWidth(ZOOM_STEPS[zoomStep].zoomWidth);
  }, [zoomStep]);

  const handleTakePhoto = () => {
    setPreviewModalOpen(false);
    resetReceipt();
    if (receipt !== null) {
      setReceipt(receipt);
    }
    setReceiptFormAction("editTakePhoto");
    setIsReceiptFormOpen(true);
  };

  const handleUploadFiles = (files: File[]) => {
    setPreviewModalOpen(false);

    files.forEach((fileItem) => addFileInput({ file: fileItem }));

    resetReceipt();

    if (receipt !== null) {
      setReceipt(receipt);
    }
    setReceiptFormAction("editUploadFile");
    setIsReceiptFormOpen(true);
  };

  const zoomImg = (zoomAction: ImgZoomType) => {
    switch (zoomAction) {
      case "in":
        zoomIn();
        break;
      case "out":
        zoomOut();
        break;
      default:
        console.log("No zoom action");
    }
  };

  const zoomIn = () => {
    if (zoomStep + 1 <= 5) {
      setZoomStep(zoomStep + 1);
    }
  };

  const zoomOut = () => {
    if (zoomStep - 1 >= 1) {
      setZoomStep(zoomStep - 1);
    }
  };

  return (
    <ResponsiveFullScreenModal isOpen={isOpen}>
      <AppBar
        sx={{
          position: "relative",
          background: "white",
          color: "black",
          marginBottom: "12px",
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h1" color="black">
            Detail
          </Typography>
          <Box mr={5}></Box>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => close()}
            aria-label="close"
            data-testid="close-billing-config-modal"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {documentPreviewType === "img" && (
              <>
                <Stack
                  spacing={1}
                  sx={{
                    position: "absolute",
                    height: "145px",
                    width: "58px",
                    textAlign: "center",
                    margin: "8px",
                    backgroundColor: "rgba(255,255,255,0.5)",
                    borderRadius: "29px",
                  }}
                >
                  <IconButton size={"large"} onClick={() => zoomImg("in")}>
                    <ZoomInIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                  {ZOOM_STEPS[zoomStep].label}%
                  <IconButton size={"large"} onClick={() => zoomImg("out")}>
                    <ZoomOutIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Stack>
                <img
                  width={imgWidth === 1 ? "auto" : imgWidth + "%"}
                  src={document}
                  alt="Document detail"
                />
              </>
            )}
            {documentPreviewType === "pdf" && (
              <Document file={document}>
                <Page
                  pageNumber={1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <AppBar
        position="relative"
        color="primary"
        sx={{ top: "auto", bottom: 0, background: "white" }}
      >
        <Toolbar>
          <Grid container spacing={2} justifyContent="space-between">
            {!isReuploadDisabled && !isBillingDisabled && (
              <>
                {!isBreakpointMd && (
                  <Grid item xs={6} md={6} textAlign="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disableElevation
                      startIcon={<AddAPhotoIcon />}
                      onClick={() => handleTakePhoto()}
                    >
                      Vyfotit znovu
                    </Button>
                  </Grid>
                )}

                <Grid item xs={6} md={6}>
                  <FileUpload
                    handleUploadFiles={handleUploadFiles}
                    multiple={false}
                  />
                </Grid>
              </>
            )}

            {isReuploadDisabled && (
              <Grid item xs={6} md={6} textAlign="center">
                <a
                  href={`${process.env.REACT_APP_SERVER_URL}/api/cashbox/${fileId}`}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disableElevation
                    startIcon={<DownloadIcon />}
                  >
                    Stáhnout
                  </Button>
                </a>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </ResponsiveFullScreenModal>
  );
};

export default DocumentPreviewModal;
