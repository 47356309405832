export interface SplitOptionValue {
  value: string;
  label: string;
}

export interface SplitOptionType {
  costType: SplitOptionValue;
  department: SplitOptionValue;
  budget: SplitOptionValue;
  contract: SplitOptionValue | null;
  usageProc?: number;
}

export interface SplitOptionContainer {
  lookups: SplitOptionType[];
  contracts: SplitOptionValue[];
}

export const isSplitOptionValue = (object: any): object is SplitOptionValue => {
  if (!object) return false;
  return "value" in object;
};
