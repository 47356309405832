// sdilena komponenta pro pridavani podkladu - 3. step uctenka & na celem vyuctovani
// 2 tlacita, vyfotit a nahrat, a pak vypis souboru
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ResponsiveFullScreenModal from "../../../../components/layout/ResponsiveFullScreenModal";
import FileUpload from "../../../../components/FileUpload";
import { SupportFileType } from "../../../../types/SupportFileType";
import moment from "moment";
import { produce } from "immer";
import SupportFileEntry from "./SupportFileEntry";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import CameraPro from "../../../../components/Camera/Camera";

interface SupportFileProps {
  setSupportFile: Dispatch<SetStateAction<SupportFileType[]>>;
  setSupportFileDelete: Dispatch<SetStateAction<number[]>>;
  supportFiles: SupportFileType[];
  uploadedFiles: number[];
  supportFilesDelete: number[];
  isDisabled: boolean;
}

const SupportFile = ({
  setSupportFile,
  supportFiles,
  uploadedFiles,
  setSupportFileDelete,
  supportFilesDelete,
  isDisabled,
}: SupportFileProps) => {
  const theme = useTheme();
  const isBreakpointMd = useMediaQuery(theme.breakpoints.up("md"));

  const getRandomId = () => {
    return Math.floor(Math.random() * 1000 + 1);
  };

  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);

  const addSupportFile = (file: SupportFileType) => {
    setSupportFile(
      produce((draft) => {
        draft?.push(file);
      })
    );
  };

  const handleTakePhoto = (base64Photo: string) => {
    console.log("handle take photo from receipt support file");
    setIsCameraOpen(false);
    const newName = "vyfoceno_" + moment().format("DD-MM-YYYY_HH:MM:SS");
    addSupportFile({ id: getRandomId(), name: newName, file: base64Photo });
  };

  const handleUploadFiles = (files: File[]) => {
    console.log("handle upload file from receipt support file");
    console.log(files);

    files.forEach((fileItem) => {
      addSupportFile({
        id: getRandomId(),
        name: fileItem.name,
        file: fileItem,
      });
    });
  };

  const removeSupportFile = (id: number) => {
    setSupportFile(
      produce((draft) => {
        const indexToDelete = draft.findIndex((fileItem) => fileItem.id === id);
        if (indexToDelete > -1) {
          draft.splice(indexToDelete, 1);
        }
      })
    );
  };

  const deleteUploadedSupportFile = (id: number) => {
    setSupportFileDelete(
      produce((draft) => {
        if (!draft.includes(id)) draft.push(id);
      })
    );
  };

  const deleteUploadedSupportFileReverse = (id: number) => {
    setSupportFileDelete(
      produce((draft) => {
        const indexToDelete = draft.indexOf(id);
        if (indexToDelete > -1) {
          draft.splice(indexToDelete, 1);
        }
      })
    );
  };

  return (
    <>
      <ResponsiveFullScreenModal isOpen={isCameraOpen}>
        <CameraPro
          takePhoto={handleTakePhoto}
          close={() => setIsCameraOpen(false)}
        />
      </ResponsiveFullScreenModal>

      <Grid container spacing={1} justifyContent="center">
        {!isDisabled && (
          <>
            {!isBreakpointMd && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  color="secondary"
                  startIcon={<AddAPhotoIcon />}
                  onClick={() => setIsCameraOpen(true)}
                >
                  Vyfotit
                </Button>
              </Grid>
            )}
            <Grid item xs={6} md={12}>
              <FileUpload
                handleUploadFiles={handleUploadFiles}
                multiple={true}
              />
            </Grid>
          </>
        )}

        <Grid container item xs={12} spacing={1}>
          {uploadedFiles.length === 0 && isBillingDisabled && (
            <Grid item xs={12} textAlign={"center"}>
              <Typography>Nenahrál si žádný podklad</Typography>
            </Grid>
          )}
          {uploadedFiles.map((fileItem) => (
            <Grid item xs={12} key={fileItem}>
              <SupportFileEntry
                file={fileItem}
                remove={deleteUploadedSupportFile}
                reverse={deleteUploadedSupportFileReverse}
                isDelete={supportFilesDelete.includes(fileItem)}
                isDisabled={isDisabled}
              />
            </Grid>
          ))}
          {supportFiles.map((fileItem) => (
            <Grid item xs={12} key={fileItem.id}>
              <SupportFileEntry
                isDelete={false}
                file={fileItem}
                remove={removeSupportFile}
                isDisabled={isDisabled}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default SupportFile;
