import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useBoundStore } from "../../utils/stores/BoundStore";

const PopupMessage = () => {
  const popupMessage = useBoundStore((state) => state.popupMessage);

  const closePopupMessage = useBoundStore((state) => state.closePopupMessage);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closePopupMessage();
  };

  return (
    <>
      {popupMessage.message && (
        <Snackbar
          open={popupMessage.message ? true : false}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Alert
            onClose={handleClose}
            severity={popupMessage.severity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {popupMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default PopupMessage;
