import { Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { SplitType } from "../../../../../types/SplitType";
import { useBoundStore } from "../../../../../utils/stores/BoundStore";
import { useCallback, useEffect, useState } from "react";
import SplitTypePicker from "./SplitTypePicker";
import {
  SplitOptionContainer,
  SplitOptionType,
  SplitOptionValue,
  isSplitOptionValue,
} from "../../../../../types/SplitOptionType";
import SplitAutocomplete from "./SplitAutocomplete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SplitFilter from "./SplitFilter";
import { produce } from "immer";
import { validateSplit } from "../SplitValidation";
import CheckIcon from "@mui/icons-material/Check";
import {
  SPLIT_AMOUNT_TYPE,
  TYPE_AMOUNT_LABEL,
} from "../../../../../utils/split";
import { roundTwoDecimals } from "../../../../../utils/number";

type SplitChangeAction = "update" | "remove";

interface SplitEntryProps {
  split: SplitType;
  splitIndex: number;
  removeSplit: (splitIndex: number) => void;
  updateSplit: (
    splitIndex: number,
    key: keyof SplitType,
    value: string | number
  ) => void;
  isStarDisabled: boolean;
  calculatedAmount: number;
  isDisabled: boolean;
}

export interface ParsedOptionsType {
  costType: SplitOptionValue[];
  department: SplitOptionValue[];
  budget: SplitOptionValue[];
  contract: SplitOptionValue[];
}

const SPLIT_OPTIONS = [
  // { optionKey: "costType", optionLabel: "Typ nákladů" },
  { optionKey: "budget", optionLabel: "Rozpočet" },
  { optionKey: "department", optionLabel: "Nákladové středisko" },
  { optionKey: "contract", optionLabel: "Zakázka" },
];

const SplitEntry = ({
  split,
  splitIndex,
  removeSplit,
  updateSplit,
  isStarDisabled,
  calculatedAmount,
  isDisabled,
}: SplitEntryProps) => {
  const splitOptions: SplitOptionContainer = useBoundStore(
    (state) => state.splitOptions
  );

  const isBillingDisabled = isDisabled;

  const billingSplitType = useBoundStore((state) => state.billingSplitType);

  const [filteredOptions, setFilteredOptions] = useState<ParsedOptionsType>({
    costType: [],
    department: [],
    budget: [],
    contract: [],
  });

  const [splitChangeAction, setSplitChangeAction] =
    useState<SplitChangeAction>("update");

  const [optionsFilter, setOptionsFilter] = useState<{
    [key: string]: string | null;
  }>({
    costType: split.costType || null,
    budget: split.budget || null,
    department: split.department || null,
    contract: split.contract || null,
  });

  const getFilteredOptions = useCallback(
    (splitOptionItem: SplitOptionType) => {
      for (const optionFilterKey in optionsFilter) {
        const filterValue = optionsFilter[optionFilterKey];

        if (filterValue === null) {
          continue;
        }

        const splitOptionValue =
          splitOptionItem[optionFilterKey as keyof SplitOptionType];

        if (isSplitOptionValue(splitOptionValue)) {
          //normal filter behaviour
          if (filterValue !== splitOptionValue.value) {
            return false;
          }
        }
      }

      return true;
    },
    [optionsFilter, splitOptions.contracts]
  );

  const handleUpdateSplit = useCallback(
    (key: keyof SplitType, value: string | number) => {
      setSplitChangeAction("update");
      updateSplit(splitIndex, key, value);
    },
    [splitIndex, updateSplit]
  );

  const handleUpdateOptionsFilter = useCallback(
    (key: keyof SplitOptionType, value: string | null) => {
      setOptionsFilter(
        produce((draft) => {
          draft[key] = value;
        })
      );
    },
    []
  );

  const handleRemoveOptionsFilter = (key: keyof SplitOptionType) => {
    setSplitChangeAction("remove");

    setOptionsFilter(
      produce((draft) => {
        draft[key] = null;

        if (key === "budget") {
          draft["costType"] = null;
        }
      })
    );
    updateSplit(splitIndex, key as keyof SplitType, "");

    if (key === "budget") {
      updateSplit(splitIndex, "costType" as keyof SplitType, "");
    }
  };

  useEffect(() => {
    if (splitOptions) {
      let rawOptions = splitOptions.lookups.filter(getFilteredOptions);

      rawOptions.sort((a: SplitOptionType, b: SplitOptionType) => {
        if (a.usageProc !== undefined && b.usageProc !== undefined) {
          return b.usageProc - a.usageProc;
        } else {
          return 0;
        }
      });

      let budget = [];
      let contract = [];
      let costType = [];
      let department = [];

      let hasNoContract = false;

      for (let i = 0; i < rawOptions.length; i++) {
        const splitOptionItem = rawOptions[i];

        // if (splitOptionItem.contract === null) {
        //   console.log(splitOptionItem);
        // }

        if (
          isObjectOptionNull(splitOptionItem.costType) ||
          isObjectOptionNull(splitOptionItem.budget) ||
          // isObjectOptionNull(splitOptionItem.contract) ||
          isObjectOptionNull(splitOptionItem.department)
        ) {
          hasNoContract = true;
          continue;
        }

        if (!isAlreadyAdded(budget, splitOptionItem.budget)) {
          budget.push(getObjectFromOption(splitOptionItem.budget));
        }

        if (
          splitOptionItem.contract &&
          splitOptionItem.contract.value !== null
        ) {
          if (!isAlreadyAdded(contract, splitOptionItem.contract)) {
            contract.push(getObjectFromOption(splitOptionItem.contract));
          }
        } else {
          hasNoContract = true;
        }

        if (!isAlreadyAdded(costType, splitOptionItem.costType)) {
          costType.push(getObjectFromOption(splitOptionItem.costType));
        }

        if (!isAlreadyAdded(department, splitOptionItem.department)) {
          department.push(getObjectFromOption(splitOptionItem.department));
        }
      }

      if (hasNoContract) {
        contract = contract.concat(splitOptions.contracts);
      }

      // console.log("available options");
      // console.log({
      //   budget: budget,
      //   contract: contract,
      //   costType: costType,
      //   department: department,
      // });
      setFilteredOptions({
        budget: budget,
        contract: contract,
        costType: costType,
        department: department,
      });
    }
  }, [getFilteredOptions, splitOptions, optionsFilter]);

  useEffect(() => {
    //AUTO-SETTING combination when one is found
    if (splitChangeAction === "update") {
      console.log("FILTERING");
      console.log(filteredOptions);
      let hasOnlyOneCombination = true;
      for (const filteredOptionKey in filteredOptions) {
        const optionValues =
          filteredOptions[filteredOptionKey as keyof ParsedOptionsType];
        if (optionValues.length !== 1) {
          hasOnlyOneCombination = false;
        }
      }

      //AUTO - SETTING costType based on budget

      if (
        filteredOptions["budget"].length === 1 &&
        filteredOptions["costType"].length === 1
      ) {
      }

      if (hasOnlyOneCombination) {
        for (const filteredOptionKey in filteredOptions) {
          const optionValues =
            filteredOptions[filteredOptionKey as keyof ParsedOptionsType];

          handleUpdateSplit(
            filteredOptionKey as keyof SplitType,
            optionValues[0].value
          );

          handleUpdateOptionsFilter(
            filteredOptionKey as keyof SplitOptionType,
            optionValues[0].value
          );
        }
      } else if (
        filteredOptions["budget"].length === 1 &&
        filteredOptions["costType"].length === 1
      ) {
        handleUpdateSplit("budget", filteredOptions["budget"][0].value);
        handleUpdateSplit("costType", filteredOptions["costType"][0].value);

        handleUpdateOptionsFilter("budget", filteredOptions["budget"][0].value);
        handleUpdateOptionsFilter(
          "costType",
          filteredOptions["costType"][0].value
        );
      }
    }
  }, [
    filteredOptions,
    handleUpdateOptionsFilter,
    handleUpdateSplit,
    splitChangeAction,
  ]);

  const getObjectFromOption = (option: SplitOptionValue) => {
    return { value: option.value, label: option.label };
  };

  const isObjectOptionNull = (option: SplitOptionValue | null): boolean => {
    if (!option) return true;
    return option.value === null || option.label === null;
  };

  const isAlreadyAdded = (
    dataArray: SplitOptionValue[],
    checkedValue: SplitOptionValue
  ) => {
    return dataArray.find(
      (dataItem) => dataItem.value === String(checkedValue.value)
    );
  };

  const getSplitValueLabel = (
    splitOptionKey: keyof ParsedOptionsType,
    value: string
  ) => {
    const foundOption = filteredOptions[splitOptionKey]?.find(
      (optionItem: SplitOptionValue) => optionItem.value === value
    );
    if (foundOption) {
      return foundOption.label + "";
    }
    return "";
  };

  return (
    <Paper
      elevation={0}
      sx={{
        background: billingSplitType === "splitReceipt" ? "#ebebeb" : "#fffff",
        padding: "12px",
      }}
      variant="outlined"
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h3">
            Rozpočet č. {splitIndex + 1}
            {validateSplit(split).isValid && (
              <CheckIcon color="success" sx={{ verticalAlign: "middle" }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign={"right"}>
          <IconButton
            disabled={isBillingDisabled}
            sx={{ padding: 0 }}
            onClick={() => removeSplit(splitIndex)}
          >
            <HighlightOffIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          Použité filtry:
          <SplitFilter
            filter={optionsFilter}
            removeFilter={handleRemoveOptionsFilter}
            getSplitValueLabel={getSplitValueLabel}
            isDisabled={isBillingDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            disabled={
              isBillingDisabled || split.amountType === SPLIT_AMOUNT_TYPE.STAR
            }
            variant="outlined"
            size="small"
            label={TYPE_AMOUNT_LABEL[split.amountType]}
            value={
              split.amountType === SPLIT_AMOUNT_TYPE.STAR
                ? roundTwoDecimals(calculatedAmount)
                : // calculatedAmount
                  split.amount
            }
            onChange={(e) =>
              handleUpdateSplit("amount", parseFloat(e.target.value))
            }
          />
        </Grid>
        <Grid item xs={6}>
          <SplitTypePicker
            split={split}
            handleUpdate={handleUpdateSplit}
            disabled={isBillingDisabled}
            isStarDisabled={isStarDisabled}
          />
        </Grid>
        {SPLIT_OPTIONS.map((splitOptionItem, index) => (
          <Grid item xs={12} key={index}>
            <SplitAutocomplete
              disabled={isBillingDisabled}
              optionKey={splitOptionItem.optionKey as keyof ParsedOptionsType}
              options={
                filteredOptions[
                  splitOptionItem.optionKey as keyof ParsedOptionsType
                ]
              }
              filterOptionKey={
                splitOptionItem.optionKey as keyof SplitOptionType
              }
              value={
                split[splitOptionItem.optionKey as keyof ParsedOptionsType]
              }
              handleUpdate={handleUpdateSplit}
              updateOptionsFilter={handleUpdateOptionsFilter}
              label={splitOptionItem.optionLabel}
              removeFilter={handleRemoveOptionsFilter}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SplitEntry;
