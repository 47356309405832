import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

type BackButtonProps = {
  to: string;
};

const BackButton = ({ to, ...rest }: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <IconButton {...rest} onClick={() => navigate(to ?? -1)}>
      <ArrowBackIcon fontSize="inherit" />
    </IconButton>
  );
};

export default BackButton;
