import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Avatar, Box, CircularProgress } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DocumentPreviewModal from "../DocumentPreviewModal";
import { Page, Document } from "react-pdf";
import { pdfjs } from "react-pdf";
import { DocumentPreviewType } from "../../types/DocumentPreviewType";
import { ReceiptType } from "../../types/ReceiptType";
import { useLazyQuery } from "@apollo/client";
import { GET_CASHBOXFILE } from "./gql";
import { useBoundStore } from "../../utils/stores/BoundStore";

interface DocumentThumbnailProps {
  fileManual: string | File | null;
  receipt: ReceiptType | null;
  fileId: number;
  isReuploadDisabled: boolean;
  setFileName?: Dispatch<SetStateAction<string>>;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentThumbnail = ({
  fileManual,
  receipt,
  fileId,
  isReuploadDisabled,
  setFileName,
}: DocumentThumbnailProps) => {
  const [
    getCashboxFile,
    {
      data: cashboxFileData,
      loading: cashboxFileLoading,
      error: cashboxFileDataErro,
    },
  ] = useLazyQuery(GET_CASHBOXFILE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [documentPreview, setDocumentPreview] = useState<{
    data: string;
    type: DocumentPreviewType;
  }>({
    data: "",
    type: "noSupport",
  });

  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const [fileString, setFileString] = useState<string | null>(null);

  const initFile = useCallback(() => {
    if (!fileString) {
      setDocumentPreview({ data: "", type: "noSupport" });
    } else if (fileString.substring(0, 5).includes("blob")) {
      const dataString = fileString;
      setDocumentPreview({
        data: dataString,
        type: "img",
      });
    } else if (fileString.includes("base64")) {
      const dataString =
        "data:image/png;base64," + fileString.split("base64,")[1];
      setDocumentPreview({
        data: dataString,
        type: "img",
      });
    } else {
      if (fileString.substring(0, 4).includes("JVB")) {
        const dataString = "data:application/pdf;base64," + fileString;
        setDocumentPreview({
          data: dataString,
          type: "pdf",
        });
      } else if (fileString.substring(0, 4).includes("/9j")) {
        const dataString = "data:image/jpeg;base64," + fileString;
        // console.log("falling here");
        setDocumentPreview({
          data: dataString,
          type: "img",
        });
      } else if (fileString.substring(0, 4).includes("/9g")) {
        const dataString = "data:image/png;base64," + fileString;
        setDocumentPreview({
          data: dataString,
          type: "img",
        });
      } else if (fileString.substring(0, 4).includes("iVBO")) {
        const dataString = "data:image/png;base64," + fileString;
        setDocumentPreview({
          data: dataString,
          type: "img",
        });
      } else {
        setDocumentPreview({ data: "", type: "noSupport" });
      }
    }
  }, [fileString]);

  useEffect(() => {
    initFile();
  }, [fileString, initFile]);

  useEffect(() => {
    if (fileManual) {
      if (typeof fileManual === "string") {
        setFileString(fileManual);
      } else {
        if (fileManual.type.includes("jpeg") || fileManual.type.includes("png"))
          setFileString(URL.createObjectURL(fileManual));
      }
    } else if (fileId) {
      getCashboxFile({
        variables: {
          fileId: fileId,
        },
        onError(error) {
          console.log(error.message);
          openPopupMessage(error?.message, "error");
        },
        onCompleted(data) {
          setFileString(data.cashboxFile.base64);
          if (setFileName) setFileName(data.cashboxFile.filename);
        },
      });
    }
  }, [fileId, fileManual, getCashboxFile, openPopupMessage, setFileName]);

  const getFile = () => {
    if (documentPreview) {
      if (documentPreview.type === "img") {
        return getFileImg(documentPreview.data);
      } else if (documentPreview.type === "pdf") {
        return getFilePdf(documentPreview.data);
      } else {
        return (
          <InsertDriveFileIcon
            sx={{
              width: "40px",
              height: "40px",
              color: "grey",
              borderRadius: "12px",
            }}
          />
        );
      }
    }
  };

  const getFilePdf = (file: string) => {
    return (
      <Box
        sx={{
          height: "40px",
          width: "40px",
          borderRadius: "6px",
        }}
        onClick={() => setIsPreviewModalOpen(true)}
      >
        <Document file={file}>
          <Page
            height={40}
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </Box>
    );
  };

  const getFileImg = (file: string) => {
    return (
      <Avatar
        src={file}
        variant="rounded"
        // sx={{ height: 70, width: 70, borderRadius: "6px" }}
        onClick={() => setIsPreviewModalOpen(true)}
      />
    );
  };

  return (
    <>
      {cashboxFileLoading ? (
        <Box display="flex">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>{getFile()}</>
      )}
      <DocumentPreviewModal
        isReuploadDisabled={isReuploadDisabled}
        isOpen={isPreviewModalOpen}
        document={documentPreview.data}
        close={() => setIsPreviewModalOpen(false)}
        documentPreviewType={documentPreview.type}
        setPreviewModalOpen={setIsPreviewModalOpen}
        receipt={receipt}
        fileId={fileId}
      />
    </>
  );
};

export default DocumentThumbnail;
