export const saveToStorage = (key, data) => {
  return new Promise((resolve, reject) => {
    sessionStorage.setItem(key, JSON.stringify(data)); //PENTEST CONF-6
    resolve();
  });
};

export const loadFromStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key)); //PENTEST CONF-6
};
