import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useBoundStore } from "../../../../utils/stores/BoundStore";

const ReceiptSplitControl = () => {
  const addSplit = useBoundStore((state) => state.addSplit);
  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);

  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={6}>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          color="secondary"
          startIcon={<AttachFileIcon />}
        >
          Soubor
        </Button>
      </Grid> */}
      {!isBillingDisabled && isReceiptEditable && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            disableElevation
            color="secondary"
            startIcon={<AddIcon />}
            onClick={addSplit}
          >
            Rozpočet
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ReceiptSplitControl;
