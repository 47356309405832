import { create, StateCreator } from "zustand";
import { createAppStore } from "./AppStore";
import { AppStore } from "./AppStore";
import { SplitStore } from "./SplitStore";
import { BillingStore, createBillingStore } from "./BillingStore";
import { createSplitStore } from "./SplitStore";
import { persist, createJSONStorage } from "zustand/middleware";
import { createReceiptStore, ReceiptStore } from "./ReceiptStore";

export const useBoundStore = create<
  AppStore & SplitStore & BillingStore & ReceiptStore
>()(
  // persist(
  (...a) => ({
    ...createAppStore(...a),
    ...createBillingStore(...a),
    ...createSplitStore(...a),
    ...createReceiptStore(...a),
  })
  // {
  //   name: "app-storage", // name of the item in the storage (must be unique)
  //   storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
  // }
  // )
);

// export const useBoundStore = create(
//   persist(
//   (...a) => ({
//     ...createAppStore(...a),
//     ...createBillingStore(...a),
//     ...createSplitStore(...a),
//     ...createReceiptStore(...a),
//   }),
//   {
//     name: "app-storage", // name of the item in the storage (must be unique)
//     storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
//   }
//   )
// );
