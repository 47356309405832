import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { UserTokenType } from "../../../../types/UserTokenType";

const CompanySelect = () => {
  const handleCompanySelect = (newToken: string) => {
    setSelectedToken(newToken);
  };

  const selectedToken = useBoundStore((state) => state.selectedToken);
  const userTokens = useBoundStore((state) => state.userTokens);
  const setSelectedToken = useBoundStore((state) => state.setSelectedToken);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="companySelect">Pro osobu</InputLabel>
        <Select
          label="Pro osobu"
          labelId="labelCompanySelect"
          id="companySelect"
          value={selectedToken}
          onChange={(e) => handleCompanySelect(e.target.value)}
        >
          {userTokens.map((tokenItem: UserTokenType) => (
            <MenuItem key={tokenItem.accessToken} value={tokenItem.accessToken}>
              {tokenItem.uniqueFullName} - {tokenItem.companyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CompanySelect;
